<template>
  <div v-if="isMobile == 0" :class="{ isGuideWhite }">
    <el-dialog
      class="exchange_dialog"
      :visible.sync="showDialog"
      :title="$t('common.exchangeSelect')"
      @close="close"
    >
      <div class="exchange_form">
        <!-- <div class="head">
          <span class="title">{{ $t("common.exchangeSelect") }}</span> -->
          <!-- <el-input
				class="search"
				:placeholder="searchValue?'':$t('common.keyword')"
				suffix-icon="el-icon-search"
				v-model="searchValue"
				@keyup.enter.native="handleSearch"
			/> -->
        <!-- </div> -->
        <div class="searchBox">
          <div class="filtrateBox">
            <div
              v-for="(item, index) in webTypeList"
              :key="index"
              :class="{ select: activeTab == index }"
              @click="selectTab(index)"
            >
              {{ item }}
            </div>
          </div>
          <el-input
            class="search"
            :placeholder="searchValue ? '' : $t('common.keyword')"
            suffix-icon="el-icon-search"
            v-model="searchValue"
            @keyup.enter.native="handleSearch"
          />
        </div>
        <div class="cont">
          <!-- 非搜索 -->
          <div class="cont_box" v-show="!isSearch">
            <div class="list" v-for="(item, index) in webList" :key="index">
              <template
                v-if="
                  activeTab == 3 &&
                  (item.name == '常用' || item.name == 'Commonly Used')
                "
              >
                <div class="title">{{ item.name }}</div>
                <div class="taglist">
                  <span
                    v-for="(it, subindex) in item.list"
                    :key="subindex"
                    :class="{ select: web == it.web }"
                    @click="handleSelect(it.name, it.web, it.id, it.web_type)"
                    >{{ it.name }}</span
                  >
                </div>
              </template>

              <template
                v-else-if="
                  (activeTab == 0 ||
                    (activeTab == 1 &&
                      item.list.some((it) => it.web_type == 0)) ||
                    (activeTab == 2 &&
                      item.list.some((it) => it.web_type == 1))) &&
                  item.name != '常用' &&
                  item.name != 'Commonly Used'
                "
              >
                <div class="title">
                  {{ item.name }}
                </div>
                <div class="taglist">
                  <template v-for="(it, subindex) in item.list">
                    <span
                      v-if="
                        activeTab == 0 ||
                        (activeTab == 1 && it.web_type == 0) ||
                        (activeTab == 2 && it.web_type == 1)
                      "
                      :key="subindex"
                      :class="{ select: web == it.web }"
                      @click="handleSelect(it.name, it.web, it.id, it.web_type)"
                      >{{ it.name }}</span
                    >
                  </template>
                </div>
              </template>
            </div>
          </div>
          <!-- 搜索结果 -->
          <div class="cont_box2" v-if="isSearch">
            <span
              class="item"
              v-for="(it, subindex) in searchList"
              :key="subindex"
              :class="{ select: web == it.web }"
              @click="handleSelect(it.name, it.web, it.id, it.web_type)"
              >{{ it.name }}</span
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
  <!-- 移动端判定 -->
  <div v-else-if="isMobile == 1" class="actionSheet" :class="{ isGuideWhite }">
    <!-- 
      :title="$t('common.exchangeSelect')" -->
    <van-action-sheet
      v-model="showDialog"
      :closeable="false"
      :cancel-text="$t('common.cancel')"
      @closed="close"
      @open="openAction"
    >
      <div class="header_phone">
        <div class="title">{{ $t("common.exchangeSelect") }}</div>
        <div class="search_box">
          <!-- input-align="right" -->
          <van-search
            v-model="searchValue"
            :clearable="false"
            right-icon="search"
            left-icon="none"
            background="transparent"
            :placeholder="searchValue ? '' : $t('common.keyword')"
            @search="handleSearch"
          />
        </div>
      </div>
      <div class="filtrateBox_phone">
        <div
          v-for="(item, index) in webTypeList"
          :key="index"
          :class="{ select: activeTab == index }"
          @click="selectTab(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="content_phone">
        <!-- 非搜索 -->
        <div class="cont_box" v-show="!isSearch">
          <div class="commonly" v-for="(item, index) in webList" :key="index">
            <template
              v-if="
                activeTab == 3 &&
                (item.name == '常用' || item.name == 'Commonly Used')
              "
            >
              <div class="title">
                {{ item.name }}
              </div>
              <div class="taglist">
                <span
                  v-for="(it, subindex) in item.list"
                  :key="subindex"
                  :class="{ select: web == it.web }"
                  @click="handleSelect(it.name, it.web, it.id, it.web_type)"
                  >{{ it.name }}</span
                >
              </div>
            </template>
          </div>
          <van-index-bar :index-list="indexList">
            <template v-for="(item, index) in webList">
              <template
                v-if="
                  (activeTab == 0 ||
                    (activeTab == 1 &&
                      item.list.some((it) => it.web_type == 0)) ||
                    (activeTab == 2 &&
                      item.list.some((it) => it.web_type == 1))) &&
                  item.name != '常用' &&
                  item.name != 'Commonly Used'
                "
              >
                <van-index-anchor :key="index" :index="item.name" />
                <template v-for="(it, subindex) in item.list">
                  <van-cell
                    v-if="
                      activeTab == 0 ||
                      (activeTab == 1 && it.web_type == 0) ||
                      (activeTab == 2 && it.web_type == 1)
                    "
                    :key="index + '_' + subindex"
                    :title="it.name"
                    @click="handleSelect(it.name, it.web, it.id, it.web_type)"
                  />
                </template>
              </template>
            </template>
          </van-index-bar>
        </div>
        <!-- 搜索结果 -->
        <div class="cont_box" v-if="isSearch">
          <div class="commonly">
            <div class="taglist">
              <span
                v-for="(it, subindex) in searchList"
                :key="subindex"
                :class="{ select: web == it.web }"
                @click="handleSelect(it.name, it.web, it.id, it.web_type)"
                >{{ it.name }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PopupManager } from "element-ui/lib/utils/popup";
export default {
  name: "exchange",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    web: {
      type: String,
      default: null,
    },
    notEl: {
      type: Boolean,
      default: false,
    },
    isGuideWhite: {
      //是不是新手引导-白色版本
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["webList", "isMobile"]),
    // 不含常用的索引列表
    indexList() {
      var arr = [];
      this.webList.forEach((item) => {
        if (item.name != "常用" && item.name != "Commonly Used") {
          if (
            this.activeTab == 0 ||
            (this.activeTab == 1 && item.list.some((it) => it.web_type == 0)) ||
            (this.activeTab == 2 && item.list.some((it) => it.web_type == 1))
          ) {
            arr.push(item.name);
          }
        }
      });
      return arr;
    },
  },
  data() {
    return {
      activeTab: 0,
      webTypeList: ["全部", "CEX", "DEX", "常用"],

      searchValue: "",
      showDialog: false,

      isSearch: false,
      searchList: [],
    };
  },
  methods: {
    selectTab(index) {
      this.activeTab = index;
      if (this.isSearch) {
        this.handleSearch();
      }
    },
    // 打开vant动作面板回调
    openAction() {
      if (this.notEl) {
        // 使用该组件的页面不存在el弹窗 使vant动作面板出现被覆盖的可能
        return;
      }
      var box = document.querySelector(".actionSheet");
      box.style["z-index"] = PopupManager.nextZIndex();
    },
    close() {
      // 搜索初始化
      this.isSearch = false;
      this.$emit("close");
    },
    handleSearch() {
      let text = this.searchValue.trim().toLowerCase();
      if (text == "") {
        // 搜索初始化
        this.isSearch = false;
      } else {
        var searchList = [];
        this.webList.forEach((item) => {
          if (
            this.activeTab == 3 &&
            (item.name == "常用" || item.name == "Commonly Used")
          ) {
            item.list.forEach((it) => {
              if (RegExp(text).test(it.name.toLowerCase())) {
                searchList.push(it);
              }
            });
          } else if (item.name != "常用" && item.name != "Commonly Used") {
            item.list.forEach((it) => {
              if (
                RegExp(text).test(it.name.toLowerCase()) &&
                (this.activeTab == 0 ||
                  (this.activeTab == 1 && it.web_type == 0) ||
                  (this.activeTab == 2 && it.web_type == 1))
              ) {
                searchList.push(it);
              }
            });
          }
        });
        this.searchList = searchList;
        this.isSearch = true;
      }
    },
    // , index, subindex
    handleSelect(text, web, id, web_type) {
      // 搜索初始化
      this.isSearch = false;
      let obj = {
        text: text,
        web: web,
        web_id: id,
        web_type: web_type,
      };
      this.$emit("select", obj);
    },
  },

  watch: {
    show(val, oldVal) {
      if (val == oldVal) {
        return;
      }
      this.searchValue = "";
      this.showDialog = val;
      // console.log(this.webList)
    },
    showDialog(val, oldVal) {
      if (val == oldVal) {
        return;
      }
      this.$emit("update:show", this.showDialog);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~@/assets/style/var.styl';

.exchange_dialog {
  /deep/ .el-dialog {
    width: 920px;

    @media screen and (max-width: 993px) {
      width: 90%;
    }

    // .el-dialog__header {
    //   display: none;
    // }

    .el-dialog__body {
      padding: 0;
    }
  }
}

.exchange_form {
  // .head {
  //   color: var(--white);
  //   height: 55px;
  //   padding: 0 24px;
  //   border-bottom: 1px solid var(--border2);
  //   flexbox();
  //   align-items: center;
  //   justify-content: space-between;

  //   .title {
  //     font-size: 16px;
  //   }
  // }

  .searchBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 40px 0;
    border-bottom: 1px solid var(--border);

    .filtrateBox {
      display: flex;
      align-items: center;

      div {
        padding: 0 10px;
        height: 31px;
        line-height: 32px;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        margin-right: 5px;

        &:hover {
          color: var(--white);
          border-color: var(--white);
        }

        &.select {
          color: var(--white);
          border-color: var(--white);
        }
      }
    }

    .search {
      width: 160px;
      padding-bottom: 3px;

      /deep/ .el-input__inner {
        color: var(--white);
        height: 32px;
        padding: 0 10px;
        border: none;
        background: none;
        line-height: 32px;
        font-size: 14px;

        @media screen and (max-width: 750px) {
          font-size: 12px;
        }
      }

      /deep/ .el-input__icon {
        line-height: 32px;
      }
    }
  }

  .cont {
    color: var(--normal);
    padding: 10px;

    .cont_box {
      padding: 14px 0px 14px 30px;
      max-height: 435px;
      overflow-y: auto;

      @media screen and (max-width: 750px) {
        padding: 14px 0px 14px 14px;
      }
    }

    .list {
      margin-bottom: 16px;

      .select {
        color: var(--active) !important;
        border-color: var(--active) !important;
      }

      .title {
        margin-bottom: 16px;
      }

      .taglist {
        flexbox();
        flex-wrap: wrap;

        span {
          padding: 0 5px;
          min-width: 87px;
          height: 32px;
          border: 1px solid var(--border2);
          boxSize(border-box);
          radius(2px);
          cursor: pointer;
          margin: 0 0.16rem 0.16rem 0;
          flexbox();
          align-items: center;
          justify-content: center;

          &:hover {
            color: var(--active) !important;
            border-color: var(--active) !important;
          }
        }
      }
    }

    // 搜索结果
    .cont_box2 {
      padding: 14px 0px 14px 30px;
      overflow-y: auto;
      flexbox();
      flex-wrap: wrap;

      .item {
        padding: 0 5px;
        min-width: 87px;
        height: 32px;
        border: 1px solid var(--border2);
        boxSize(border-box);
        radius(2px);
        cursor: pointer;
        margin: 0 0.16rem 0.16rem 0;
        flexbox();
        align-items: center;
        justify-content: center;

        &:hover {
          color: var(--active) !important;
          border-color: var(--active) !important;
        }
      }
    }
  }
}

// 移动端判定
// 动作面板
/deep/ .van-action-sheet__content {
  padding: 0;
  display: flex;
  flex-direction: column;

  .search_box {
    padding: 0 16px;
  }

  .cont_box, .van-index-anchor, .cont_box .van-cell {
    padding-left: 22px;
    padding-right: 22px;
  }

  .van-cell:after {
    left: 22px;
    right: 22px;
  }
}

.van-search__content {
  border: none;
}

.header_phone {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  border-bottom: 1px solid var(--border2);
  color: var(--white);
}

.filtrateBox_phone {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border2);
  margin: 0 22px;

  div {
    padding: 12px 10px 6px;
    height: 29px;
    line-height: 29px;
    border-bottom: 3px solid transparent;
    cursor: pointer;

    &:hover {
      color: var(--white);
      border-color: var(--white);
    }

    &.select {
      color: var(--white);
      border-color: var(--white);
    }
  }
}

.content_phone {
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 4px 0 12px;

  .cont_box {
    .commonly {
      margin-top: 10px;

      .title {
        font-size: 0.26rem;
        color: var(--active);
        margin-bottom: 0.2rem;
      }

      .taglist {
        display: flex;
        flex-wrap: wrap;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--phoneTagBarBg);
          padding: 0 0.1rem;
          min-width: 1.1rem;
          margin-right: 0.2rem;
          margin-bottom: 0.2rem;
          border-radius: 4px;
          min-height: 0.42rem;
          color: var(--white);
        }
      }
    }
  }
}

.actionSheet {
  position: relative;
}

// 新手引导-白色版本
.isGuideWhite {
  /deep/ .el-dialog {
    background-color: #fff;
  }

  .exchange_form {
    .head {
      color: #333;
      border-bottom: 1px solid #DBDBDB;

      .search {
        /deep/ .el-input__inner {
          color: #333;
          border-color: #DBDBDB;
        }
      }
    }

    .searchBox {
      border-bottom: 1px solid #DBDBDB;

      .filtrateBox {
        div {
          &:hover {
            color: #333;
            border-color: #333;
          }

          &.select {
            color: #333;
            border-color: #333;
          }
        }
      }

      .search {
        /deep/ .el-input__inner {
          color: #333;
        }
      }
    }

    .cont {
      color: #333;

      .list {
        .taglist {
          span {
            border: 1px solid #DBDBDB;
          }
        }
      }

      .cont_box2 {
        .item {
          border: 1px solid #DBDBDB;
        }
      }
    }
  }

  // 移动端判定
  // 滚动条
  ::-webkit-input-placeholder {
    color: #999;
  }

  // 轨道
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  // 滑块
  ::-webkit-scrollbar-thumb {
    background-color: #999;
  }

  // 动作面板
  /deep/ .van-popup {
    color: #606266;
    background-color: #fff;

    .van-action-sheet {
      color: rgba(0, 0, 0, 0.45);
    }

    .van-action-sheet__header {
      border-bottom: 1px solid #DBDBDB;
    }

    .van-action-sheet__gap { // 间隔
      background-color: #121212;
    }

    .van-action-sheet__cancel {
      background-color: #fff;
    }

    .van-action-sheet__cancel:active, .van-action-sheet__item:active {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  // 索引栏
  /deep/ .van-index-bar {
    // 索引侧边栏
    .van-index-bar__sidebar {
      color: #333;
    }

    // 索引标题
    .van-index-anchor {
      background: rgba(0, 0, 0, 0.14);
    }

    // 单项
    .van-cell {
      background: #fff;
      color: #333;

      &:after {
        border-color: #DBDBDB;
      }
    }
  }

  .header_phone {
    border-bottom: 1px solid #DBDBDB;
    color: #333;

    /deep/.van-search__content {
      .van-field__control {
        color: #333;
      }

      .van-icon-search {
        color: #333;
      }
    }
  }

  .filtrateBox_phone {
    border-bottom: 1px solid #DBDBDB;

    div {
      &:hover {
        color: #333;
        border-color: #333;
      }

      &.select {
        color: #333;
        border-color: #333;
      }
    }
  }

  .content_phone {
    .cont_box {
      .commonly {
        .taglist {
          span {
            background: #F7F6F5;
            color: #333;
          }
        }
      }
    }
  }
}
</style>
