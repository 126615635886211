<template>
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[5]}}</span>
			</div>

			<div class="content">
				<div class="content-container">
					<div class="property_box">
						<div class="head">
							<div class="item">
								<span>{{$t('common.selectdate')}}</span>
								<el-date-picker :editable="false" :clearable="false" class="datetime"
									v-model="dateValue" value-format="timestamp" type="date"
									:placeholder="$t('common.selectdate')" :picker-options="pickerOptions"
									@change="changeDate" />
							</div>
							<div class="btn_box">
								<div class="g_btn btn" @click="handlePrev">{{$t('common.beforeDay')}}</div>
								<div class="g_btn btn" @click="handleNext" :class="{disabled:noNext}">
									{{$t('common.nextDay')}}</div>
							</div>
						</div>
						<div class="cont">
							<div class="cont_box">
								<template v-if="propList.length">
									<div class="property_list" v-for="(item,index) in propList" :key="index">
										<div class="caption">
											<div class="left">
												<span>{{$t('invite.account')}}：{{item.user_name}}</span>
												<span>{{$t('common.exchange')}}：{{item.web_name}}</span>
											</div>
											<div class="right">
												<div class="g_btn download" @click="downLoad(item)">
													{{$t('common.downLoad')}}</div>
												<div class="sub g_btn" @click="jumpSubManage(item)"
													v-if="item.web_type==1">{{$t('common.subManage')}}</div>
											</div>
										</div>
										<div class="hd">
											<div class="unit">{{$t('common.currency')}}</div>
											<div class="available">{{$t('common.available')}}</div>
											<div class="frozen">{{$t('common.frozen')}}</div>
											<div class="change">{{$t('common.change')}}</div>
											<div class="total">{{$t('common.total')}}</div>
										</div>
										<div class="bd">
											<div class="item" v-for="(value,key,subindex) in item.balanceObj"
												:key="subindex">
												<div class="unit">{{key}}</div>
												<div class="available">{{value.balance}}</div>
												<div class="frozen"><span class="red">{{value.freeze}}</span></div>
												<div class="change"><span class="green">{{value.change||0}}</span></div>
												<div class="total">{{Number(value.balance) + Number(value.freeze)}}
												</div>
											</div>
										</div>
									</div>
								</template>
								<el-empty v-else :image="emptyImg" :image-size="120"
									:description="$t('property.propertyEmpty')" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="content-container">
					<!-- 日期选择器 -->
					<van-calendar v-model="date_vant_show" color="#E65B1B" :default-date="dateValue" :min-date="minDate"
						:max-date="maxDate" @confirm="setDate" />

					<div class="property_box">
						<div class="head">
							<div class="g_btn btn" @click="handlePrev">
								<i class="iconfont2 icon-left"></i>
							</div>

							<div class="date" @click="date_vant_show=true">{{nowDate}}</div>

							<div class="g_btn btn" @click="handleNext" :class="{disabled:noNext}">
								<i class="iconfont2 icon-right_arrow"></i>
							</div>
						</div>
						<div class="cont">
							<div class="cont_box">
								<template v-if="propList.length">
									<div class="property_list" v-for="(item,index) in propList" :key="index">
										<div class="caption">
											<div class="left">
												<span>{{$t('invite.account')}}：{{item.user_name}}</span>
												<span>{{$t('common.exchange')}}：{{item.web_name}}</span>
											</div>
											<div class="right">
												<div class="g_btn download" @click="downLoad(item)">
													{{$t('common.downLoad')}}</div>
												<div class="sub g_btn" @click="jumpSubManage(item)"
													v-if="item.web_type==1">{{$t('common.subManage')}}</div>
											</div>
										</div>
										<div class="hd">
											<div class="unit">{{$t('common.currency')}}</div>
											<div class="change">{{$t('common.change')+'/'+$t('common.frozen')}}</div>
											<div class="total">{{$t('common.available')+'/'+$t('common.total')}}</div>
										</div>
										<div class="bd">
											<div class="item" v-for="(value,key,subindex) in item.balanceObj"
												:key="subindex">
												<div class="unit">{{key}}</div>
												<div class="change">
													<div class="red">{{value.freeze}}</div>
													<div class="green">{{value.change||0}}</div>
												</div>
												<div class="total">
													<div>{{value.balance}}</div>
													<div>{{Number(value.balance) + Number(value.freeze)}}</div>
												</div>
											</div>
										</div>
									</div>
								</template>
								<el-empty v-else :image="emptyImg" :image-size="120"
									:description="$t('property.propertyEmpty')" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>

		<!-- 导出 -->
		<el-dialog class="confirmMsg" :title="$t('tip.export',{exchange:web_name})" :visible.sync="showExport">
			<div class="tips_form">
				<!-- <div class="tip_title">{{$t('tip.export',{exchange:web_name})}}</div> -->
				<div class="time_select_box">
					<div class="label">{{$t('property.selectTime')}}: </div>
					<div class="time_select">
						<el-select v-model="timeType" :placeholder="$t('tip.selectTimeTip')">
							<el-option :label="$t('property.option1')" :value="0">
							</el-option>
							<el-option :label="$t('property.option2')" :value="1">
							</el-option>
							<el-option :label="$t('property.option3')" :value="2">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="btn_box">
					<div class="g_btn btn" @click="showExport=false">{{$t('common.cancel')}}</div>
					<div class="g_btn btn submit" @click="downLoadExport">{{$t('common.confirm')}}</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'index.styl';
</style>

<script>
	import { Loading } from 'element-ui';
	import timeFormat from '@/utils/methApi/timeFormat'
	import { mapGetters } from 'vuex';
	// api
	import { getAssetsList, exportAssets } from '@/api/property'
	// 三按钮弹窗
	export default ({
		name: 'robotlog',
		data() {
			return {
				emptyImg: require('@/assets/images/noresult.png'),
				// 日期选择器
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},

				propList: [],
				dateValue: new Date(),
				// 导出
				showExport: false,
				web_name: '',
				web: '',
				timeType: 0,// 导出时间段 0-近1个月 1-近3个月 2-近6个月

				// 日期选择器-移动端
				date_vant_show: false,
			}
		},
		computed: {
			...mapGetters(['account', 'isMobile']),
			noNext() {
				var now = timeFormat(new Date(), 'date');
				var nowTime = new Date(now).getTime() - 8 * 60 * 60 * 1000;
				var time = this.dateValue - nowTime;
				return time >= 0
			},
			nowDate() {
				return timeFormat(this.dateValue, 'date');
			},
			minDate() {
				return new Date(this.dateValue - 365 * 24 * 60 * 60 * 1000);
			},
			maxDate() {
				return new Date();
			},
		},
		created() {
			this.getList()
		},
		methods: {
			// 导出
			downLoadExport() {
				exportAssets({
					account: this.account,
					web: this.web,
					type: this.timeType
				}).then(res => {
					// console.log(res)

					const blob = new Blob([res]);
					const fileName = 'assets_stat_' + this.account + '_' + this.web + '.xlsx';
					const elink = document.createElement('a');
					elink.download = fileName;
					elink.style.display = 'none';
					elink.href = URL.createObjectURL(blob);
					document.body.appendChild(elink);
					elink.click();
					URL.revokeObjectURL(elink.href); // 释放URL 对象
					document.body.removeChild(elink);

					this.showExport = false;
				})
			},
			// 是否导出
			downLoad(item) {
				this.web_name = item.web_name
				this.web = item.web
				this.timeType = 0;
				this.showExport = true;
			},
			// 交易钱包管理
			jumpSubManage(item) {
				// console.log(item)
				this.$router.push({
					path: "/subManage",
					query: {
						web: item.web,
						exchange: item.web_name,
						// yenUnit1:this.yenUnit1,
						// yenUnit2:this.yenUnit2,
					}
				})
			},
			getList() {
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});

				getAssetsList({
					time: timeFormat(this.dateValue, 'date'),
					account: this.account,
				}).then(res => {
					console.log(res)
					if (!res) {
						this.propList = []
					} else {
						res.forEach(item => {
							item.balanceObj = JSON.parse(item.balance)
						});
						this.propList = res;
					}
					loading.close();
				}).catch(err => {
					loading.close();
				})
			},
			handlePrev() {
				// console.log(this.dateValue);
				if (this.dateValue) {
					var prev = new Date(new Date(this.dateValue).getTime() - 86400000)
					this.$set(this, 'dateValue', prev)
					// 获取数据
					this.getList()
				}
			},
			handleNext() {
				// console.log(this.dateValue);
				if (this.dateValue && !this.noNext) {
					var next = new Date(new Date(this.dateValue).getTime() + 86400000)
					this.$set(this, 'dateValue', next)
					// 获取数据
					this.getList()
				}
			},
			changeDate() {
				this.getList();
			},
			setDate(e) {
				// console.log(this.dateValue,e)
				this.dateValue = e;
				// this.$set(this, 'dateValue', e)
				this.date_vant_show = false;
				this.getList();
			},
		}
	})
</script>