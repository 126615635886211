<template>
    <div class="container">
        <div class="f1">
            <div class="con_box">
                <div class="icon">
                    <img src="@/assets/images/official/index/f1_icon.webp" :alt="$t('website.index.lightMM')">
                </div>
                <h1 class="title wow animated fadeInLeft">
                    <span class="gradient">{{$t('common.title')}} {{vNum}}</span>
                </h1>
                <h2 class="sub wow animated fadeInLeft">
                    <span class="gradient">{{$t('website.index.sub')}}</span>
                </h2>
                <div class="data_box">
                    <div class="wow animated fadeInUp">
                        <div class="data">1000+</div>
                        <div class="name">{{$t('common.pair')}}</div>
                    </div>
                    <div class="wow animated fadeInUp">
                        <div class="data">200+</div>
                        <div class="name">{{$t('common.exchange')}}</div>
                    </div>
                    <div class="wow animated fadeInUp">
                        <div class="data">700+</div>
                        <div class="name">{{$t('website.index.project')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="f2">
            <div class="con_box">
                <h2 class="title wow animated fadeInLeft">
                    <span class="gradient">{{$t('website.index.multiChain')}}</span>
                </h2>
                <h3 class="sub wow animated fadeInLeft">
                    <span class="gradient">ETH BSC HECO OEC TRON Polygon FanTom…</span>
                </h3>
                <div class="card_list">
                    <div class="item wow animated fadeInUp">
                        <div class="icon">
                            <img src="@/assets/images/official/index/f2_icon1.webp" alt="">
                        </div>
                        <label class="text">{{$t('website.index.fullyAutomatic')}}</label>
                    </div>
                    <div class="item wow animated fadeInUp">
                        <div class="icon">
                            <img src="@/assets/images/official/index/f2_icon2.webp" alt="">
                        </div>
                        <label class="text">{{$t('website.index.followSystem')}}</label>
                    </div>
                    <div class="item wow animated fadeInUp">
                        <div class="icon">
                            <img src="@/assets/images/official/index/f2_icon3.webp" alt="">
                        </div>
                        <label class="text">{{$t('website.index.sellSuck')}}</label>
                    </div>
                    <div class="item wow animated fadeInUp">
                        <div class="icon">
                            <img src="@/assets/images/official/index/f2_icon4.webp" alt="">
                        </div>
                        <label class="text">{{$t('website.index.assetWarning')}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="f3">
            <div class="con_box">
                <h2 class="title wow animated fadeInLeft">
                    <span class="gradient">{{$t('website.index.packages')}}</span>
                </h2>

                <div class="card_list">
                    <div class="item_box wow animated fadeInUp" v-for="(item,key,index) in vipList" :key="index">
                        <!-- 推荐 -->
                        <div class="notShow" :class="{recommend:key==2}">
                            {{key==2?$t('common.recommend'):''}}
                        </div>
                        <div class="item">
                            <div class="caption">
                                <label class="gradient">{{item.name}}</label>
                                <label class="sub gradient">{{$t('website.index.mm')}}</label>
                                <div class="price">
                                    <!-- {{item.price}}<span>USDT/{{$t('website.index.month')}}</span> -->
                                </div>
                            </div>
                            <div class="bd">
                                <div class="list">
                                    <div class="sub" v-if="key==2||key==4">
                                        <span class="gradient">{{key==2?
                                            $t('renew.include')+$t('level[0]'):$t('renew.include')+$t('level[1]')}}</span>
                                    </div>
                                    <ul class="g_list">
                                        <li v-for="(it,subindex) in item.features" :key="subindex">
                                            <div class="img">
                                                <img src="@/assets/images/official/index/f3_icon.png" alt="○">
                                            </div>
                                            <label class="name">{{it.name}}</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="f4">
            <div class="con_box">
                <h2 class="title wow animated fadeInLeft">
                    <span class="gradient">{{$t('website.index.features')}}</span>
                </h2>
                <h4 class="sub wow animated fadeInLeft">
                    <span class="gradient">{{$t('website.index.featuresDetail')}}</span>
                </h4>
                <div class="f4_con">
                    <div class="left wow animated fadeInLeft">
                        <img src="@/assets/images/official/index/f4.webp" alt="">
                    </div>
                    <div class="right">
                        <div class="item wow animated fadeInRight">
                            <div class="icon">
                                <img src="@/assets/images/official/index/f4_icon1.webp" alt="">
                            </div>
                            <div class="text">
                                <div class="text_title">{{$t('website.index.safe')}}</div>
                                <div class="text_sub">{{$t('website.index.safeSub')}}</div>
                            </div>
                        </div>

                        <div class="item wow animated fadeInRight">
                            <div class="icon">
                                <img src="@/assets/images/official/index/f4_icon2.webp" alt="">
                            </div>
                            <div class="text">
                                <div class="text_title">{{$t('website.index.power')}}</div>
                                <div class="text_sub">{{$t('website.index.powerSub')}}</div>
                            </div>
                        </div>

                        <div class="item wow animated fadeInRight">
                            <div class="icon">
                                <img src="@/assets/images/official/index/f4_icon3.webp" alt="">
                            </div>
                            <div class="text">
                                <div class="text_title">{{$t('website.index.convenient')}}</div>
                                <div class="text_sub">{{$t('website.index.convenientSub')}}</div>
                            </div>
                        </div>

                        <div class="item wow animated fadeInRight">
                            <div class="icon">
                                <img src="@/assets/images/official/index/f4_icon4.webp" alt="">
                            </div>
                            <div class="text">
                                <div class="text_title">{{$t('website.index.efficient')}}</div>
                                <div class="text_sub">{{$t('website.index.efficientSub')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="f5">
            <div class="con_box">
                <h2 class="title wow animated fadeInLeft">
                    <span class="gradient">{{$t('website.index.customers')}}</span>
                </h2>
                <h4 class="sub wow animated fadeInLeft">
                    <span class="gradient">{{$t('website.index.who')}}</span>
                </h4>
                <div class="f5_con">
                    <div class="left">
                        <div class="item wow animated fadeInLeft">
                            <div class="icon">
                                <img src="@/assets/images/official/index/f5_icon1.webp" alt="">
                            </div>
                            <label class="text">{{$t('website.index.market')}}</label>
                        </div>

                        <div class="item wow animated fadeInLeft">
                            <div class="icon">
                                <img src="@/assets/images/official/index/f5_icon2.webp" alt="">
                            </div>
                            <label class="text">{{$t('common.exchange')}}</label>
                        </div>

                        <div class="item wow animated fadeInLeft">
                            <div class="icon">
                                <img src="@/assets/images/official/index/f5_icon3.webp" alt="">
                            </div>
                            <label class="text">{{$t('website.index.project')}}</label>
                        </div>

                        <div class="item wow animated fadeInLeft">
                            <div class="icon">
                                <img src="@/assets/images/official/index/f5_icon4.webp" alt="">
                            </div>
                            <label class="text">{{$t('website.index.incubator')}}</label>
                        </div>
                    </div>
                    <div class="right">
                        <img src="@/assets/images/official/index/f5.webp" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="foot">
            <div class="con_box">
                <div class="left">
                    <div class="foot_left_top">
                        <div class="foot_logo">
                            <img src="@/assets/images/official/logo.png" alt="Lightning logo">
                        </div>
                        <p class="foot_rights">@ 2022 LLKKZ.NET ALL RIGHTS RESERVED</p>
                    </div>
                    <div class="foot_contact">
                        <div class="foot_contact_sub">{{$t('common.contact')}}</div>
                        <div class="foot_icon_list">
                            <i @click="handleSelect(0)" class="iconfont icon-telegram"></i>
                            <i @click="handleSelect(1)" class="iconfont icon-facebook"></i>
                            <i @click="handleSelect(2)" class="iconfont icon-twitter"></i>
                            <i @click="handleSelect(3)" class="iconfont icon-discord"></i>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <ul class="foot_right_list">
                        <li class="foot_right_title">{{$t('website.index.links')}}</li>
                        <li>
                            <a href="http://follow.light22.net/#/registerPage?refcode=ENFFXV&nonce=9966">
                                {{$t('website.index.follow')}}
                            </a>
                        </li>
                        <li>
                            <a href="http://dextools.llkkz.net">
                                {{$t('website.index.LightningDexTools')}}
                            </a>
                        </li>
                    </ul>
                    <ul class="foot_right_list">
                        <li class="foot_right_title">{{$t('website.index.service')}}</li>
                        <li>
                            <router-link to="/homeContact">{{$t('website.index.cooperation')}}</router-link>
                        </li>
                        <li>
                            <router-link to="/problem">{{$t('website.index.problem')}}</router-link>
                        </li>
                        <!-- <li>
                            <a href="https://github.com/arvon2012/bot-API/blob/main/README.md">API{{$t('common.documentation')}}</a>
                        </li> -->
                    </ul>
                    <ul class="foot_right_list">
                        <li class="foot_right_title">{{$t('website.index.terms')}}</li>
                        <li>
                            <router-link to="/agreement">{{$t('common.agreement')}}</router-link>
                        </li>
                        <li>
                            <!-- <a> -->
                            {{$t('website.index.privacy')}}
                            <!-- </a> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 联系我们弹窗 -->
        <el-dialog class="w900" :title="$t('common.contact')" :visible.sync="showContact">
            <ul class="contaceList">
                <li v-for="(item,index) in contactList" :key="index" @click="jumpOut(item.wechat)">
                    <i v-if="sourceImg==0" class="iconfont icon-telegram"></i>
                    <i v-else-if="sourceImg==1" class="iconfont icon-facebook"></i>
                    <i v-else-if="sourceImg==2" class="iconfont icon-twitter"></i>
                    <i v-else-if="sourceImg==3" class="iconfont icon-discord"></i>
                    <span>{{item.nickname}}</span>
                </li>
            </ul>
        </el-dialog>
    </div>
</template>
<script>
    import { WOW } from 'wowjs'
    import { mapGetters } from "vuex"
    // api
    import { getService } from '@/api/contact'
    export default {
        data() {
            return {
                showContact: false,
                contactList: [],
                sourceImg: 0,//联系方式icon 0-电报 1-facebook 2-twitter 3-discord
            }
        },
        computed: {
            ...mapGetters(['vipList', 'version']),
            vNum() {
                // return this.version.split('.')[0]+'.'+this.version.split('.')[1]
                var versionArr = this.version.split('.')
                return versionArr[0] + '.' + versionArr[1]
            }
        },
        created() {
            this.$store.dispatch('markets/getVipList').then(() => {
                this.$nextTick(() => {
                    this.wow();
                })
            })
        },
        methods: {
            wow() {
                if (!(/msie [6|7|8|9]/i.test(navigator.userAgent))) {// 在非 IE 6-9 浏览器中执行逻辑
                    var wow = new WOW({
                        boxClass: 'wow',
                        animateClass: 'animated',
                        offset: 50,
                        mobile: true,
                        live: true
                    });
                    this.$nextTick(() => {
                        wow.init();
                    });
                };
            },
            // 跳外部链接
            jumpOut(path) {
                window.location.href = path
            },
            // 联系我们
            handleSelect(data) {
                this.sourceImg = data
                var source;//来源
                switch (data) {
                    case 0:
                        source = 1;
                        break;
                    case 1:
                        source = 2;
                        break;
                    case 2:
                        // source=0;//
                        source = 4;//
                        break;
                    case 3:
                        source = 3;
                        break;
                    default:
                        break;
                }
                getService({
                    account: '',
                    source,//0-微信，1-telegram，2-Facebook，3-discord
                }).then(res => {
                    // console.log(res)
                    // window.open(res[0].wechat, '_blank');
                    if (!!res) {
                        if (res.length == 1) {
                            // 如果只有一个联系方式 直接打开
                            window.location.href = res[0].wechat
                        } else if (res.length > 1) {
                            // 如果有多个联系方式 打开弹窗展示列表
                            this.contactList = res
                            this.showContact = true
                        } else {
                            // 没有联系方式
                        }
                    }
                })
            }
        },
    }
</script>
<style scoped="scoped" lang="stylus">
    @import './index.styl';
    @import '~@/assets/style/frames.styl';
</style>