// 账号列表
import {http,http_no_aes} from '@/utils/http'

// 3.6获取绑定交易所账号接口
export const getAccountList = data => http('/Wallet/get_access_token',data,'post')
// 3.7绑定/修改交易所账号接口
export const setAccountItem = data => http_no_aes('/Wallet/update_access_token',data,'post')
// 3.8删除已绑定交易所账号接口
export const delAccountItem = data => http_no_aes('/Wallet/del_access_token',data,'post')

// 创建主钱包
export const createDexWalletsMain = data => http_no_aes('/Wallet/create_dex_wallets_main',data,'post')