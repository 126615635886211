<template>
	<div id="app" @mousemove="handleMouseMove" @touchmove="handleMouseMove">
		<router-view></router-view>
		<!-- gpt客服按钮 -->
		<div :style="{ left: x + 'px', top: y + 'px' }" @mousedown="handleMouseDown" @mouseup="handleMouseUp"
			@touchstart="handleMouseDown" @touchend="handleMouseUp" class="gptBtn"></div>
		<!-- 拖拽遮罩层 避免iframe的影响 -->
		<div class="iframe-mask" v-if="isMouseDown"></div>
		<!-- chat弹窗 -->
		<div class="gptDialog" v-if="isGpt" :class="{'fullscreen':isMobile==1}">
			<localToast ref="localToast"></localToast>
			<div class="gptDialogTitle">
				<div>
					<i class="iconfont2 icon-kefu"></i>
					{{$t('common.customerService')}}
				</div>
				<div class="gptDialogCloseBox">
					<div class="clear" @click="clearHistory">{{$t('common.clearGptHistory')}}</div>
					<div class="close" @click="isGpt = false">x</div>
				</div>
			</div>
			<div class="gptDialogContainer" id="gptDialogContainer">
				<div :class="item.sender" v-for="(item,index) in chatHistory" :key="index">
					<!-- gpt客服版本1 -->
					<!-- <span v-if="item.sender=='user'">{{item.messageText}}</span>
					<span v-else v-html="item.messageText"></span> -->
					<!-- gpt客服版本2 -->
					<span :class="{errText:item.isErr==1}">{{item.messageText}}</span>
				</div>
				<div class="assistant" v-if="gptResIng">
					<span class="typingIndicator">
						<span></span>
						<span></span>
						<span></span>
					</span>
				</div>
			</div>
			<div class="gptInpBox">
				<el-input @keyup.enter.native="sendMsg" v-model="gptMsg" type="text" :placeholder="gptMsg?'':$t('common.gptMsg')"
					class="inp" />
				<button class="sendBtn" @click="sendMsg">{{$t("common.sendGpt")}}</button>
			</div>
		</div>
	</div>
</template>

<script>
	var that;
	import { mapGetters } from 'vuex'
	import localToast from '@/components/gptDialog/localToast.vue'
	// api
	import { compareVersion, gptRequest } from '@/api/user'
	import { Loading } from 'element-ui';
	import axios from 'axios'
	import { getTradingPairInfo } from "@/utils/methApi/tradingPairDefault";
	export default ({
		name: 'APP',
		// 动态title、meta
		metaInfo() {
			if (this.$route.meta.title) {
				// console.log(this.$route.name)
				if (
					this.$route.name == 'agreement' ||
					this.$route.name == 'problem' ||
					this.$route.name == 'homeContact' ||
					this.$route.name == 'login' ||
					this.$route.name == 'safeLogin' ||
					this.$route.name == 'forget' ||
					this.$route.name == 'register'
				) {
					// 外部页面-无需登录
					return {
						title: this.$t(this.$route.meta.title) + ' - ' + this.$t('common.title'),
						mate: this.$route.meta.metaData || []
					}
				} else {
					// 内部页面-登录后访问
					return {
						// title: this.$t(this.$route.meta.title),
						title: this.$t('common.title'),
						mate: this.$route.meta.mateData || []
					}
				}
			} else {
				return {
					title: this.$t('common.title'),
				}
			}
		},
		components: { localToast },
		data() {
			return {
				timer: null,
				isGpt: false,
				gptMsg: '',
				chatHistory: [],
				currentAssistantMessage: null,//当前机器人回答msg的index
				socket: null,
				gptResIng: false,//是否在回答中
				loadBox: null,//chat loading状态
				heartbeatNum: 0,//心跳计数

				// 拖拽
				x: window.innerWidth - 28 - 40,  // 元素的横坐标
				y: window.innerHeight - 28 - 50,  // 元素的纵坐标
				isMouseDown: false,  // 是否处于鼠标按下状态
				isDragging: false,  // 是否正在拖拽
				mouseOffsetX: 0,  // 鼠标点击位置和元素左上角的偏移量
				mouseOffsetY: 0,
				mouseDownX: 0,  // 鼠标点击的初始位置
				mouseDownY: 0,
				// 页面宽度、高度
				pageWidth: window.innerWidth,
				pageHeight: window.innerHeight,
			}
		},
		computed: {
			...mapGetters(['theme', 'version', 'isMobile', 'account',
				'isMouseDown2', 'isDragging2', 'mouseOffsetX2', 'mouseOffsetY2', 'mouseDownX2', 'mouseDownY2']),
			path() {
				return this.$route.path
			},
		},
		created() {
			that = this;

			// 加载gpt聊天历史
			const chatHistory = JSON.parse(localStorage.getItem("chatHistory") ||
				"[]");
			chatHistory.forEach((message) => {
				this.displayChatMessage(message.sender, message.messageText, message.isErr);
			});

			// socket link
			// this.socketConnect()

			window.addEventListener("resize", this.updatePageWidthOrHeight);
		},
		mounted() {
			// 主题切换
			document.getElementsByTagName('html')[0].setAttribute('data-theme', this.theme)
		},
		methods: {
			// 版本升级提示
			compareVersion() {
				compareVersion({
					version: this.version,
					ignore_version: localStorage.getItem('ignore_version') || this.version,
				})
			},
			socketConnect() {
				this.socket = new WebSocket("wss://wss.llkkz.net/websocket");

				this.socket.onopen = (event) => {
					console.log("WebSocket connection opened.");
					if (this.loadBox && this.loadBox != 1) {
						this.loadBox.close()
					}
					// 重连成功，清除loading状态
					this.loadBox = null;
				};

				this.socket.onmessage = (event) => {
					const data = event.data;

					if (data === 'heartbeat') {
						// Respond to heartbeat message with the same message
						this.socket.send('heartbeat');
						console.log("WebSocket heartbeat");
						this.heartbeatNum++
						var nextHeartbeatNum = this.heartbeatNum + 1
						// 设置定时器如果30s后没再次收到heartbeat则主动触发断连(并自动重连)
						setTimeout(() => {
							if (this.heartbeatNum < nextHeartbeatNum) {
								this.socket.close()
							}
						}, 30000)
					} else if (data === 'END_OF_RESPONSE') {
						console.log("End of GPT-3.5-turbo response.");
						this.gptResIng = false;
						// 滚动到聊天窗底部
						this.scrollBottom()
					} else {
						// Existing code for handling messages
						// const currentTimestamp = new Date().toLocaleTimeString();
						const formattedData = data.endsWith('\n') ? data.slice(0, -1) + '<br>' : data;
						this.displayChatMessage("assistant", formattedData);
						this.storeChatMessage("assistant", data);

						// 滚动到聊天窗底部
						this.scrollBottom()
					}
				};

				this.socket.onclose = (event) => {
					console.log("WebSocket connection closed.");
					this.$nextTick(() => {
						if (this.isGpt && document.querySelector('.gptDialog')) {
							// 如果打开显示loading
							// loading，提示检测到连接断开，正在重连...
							if (!this.loadBox || this.loadBox == 1) {
								this.loadBox = Loading.service({
									target: '.gptDialog',
									text: this.$t('common.socketClose'),
									spinner: 'el-icon-loading',
									background: 'rgba(0, 0, 0, 0.7)',
									customClass: 'apploading',
								});
							}
						} else {
							this.loadBox = 1
						}
					})
					console.log('WebSocket reconnecting...');
					this.socketConnect();
					//发送瞬间断连,重置发送限制
					this.gptResIng = false
				};

				this.socket.onerror = (event) => {
					console.error("WebSocket error:", event);
					this.gptResIng = false;
				};
			},
			openChat() {
				if (!this.isGpt) {
					this.isGpt = true
					this.$nextTick(() => {
						if (this.loadBox && document.querySelector('.gptDialog')) {
							this.loadBox = Loading.service({
								target: '.gptDialog',
								text: this.$t('common.socketClose'),
								spinner: 'el-icon-loading',
								background: 'rgba(0, 0, 0, 0.7)',
								customClass: 'apploading',
							});
						}
					})
					this.scrollBottom()
				} else {
					this.isGpt = false
				}
			},
			// 提问/机器人回答
			displayChatMessage(sender, messageText,isErr=0) {
				if (sender === "assistant") {
					this.$set(this.chatHistory, this.currentAssistantMessage, {
						sender,
						messageText: (this.chatHistory[this.currentAssistantMessage]?.messageText || '') + messageText,
						isErr
					})
				} else {
					this.chatHistory.push({
						sender,
						messageText
					})
					this.currentAssistantMessage = this.chatHistory.length
				}
			},
			// 存储历史记录
			storeChatMessage(sender, messageText,isErr=0) {
				const chatHistory = JSON.parse(localStorage.getItem("chatHistory") || "[]");
				chatHistory.push({ sender, messageText, isErr });
				localStorage.setItem("chatHistory", JSON.stringify(chatHistory));
			},
			// 清空历史记录
			clearHistory() {
				this.$set(this, 'chatHistory', [])
				localStorage.setItem("chatHistory", "");
			},
			sendMsg() {
				// 防止重复提交-版本2使用，版本1隐藏
				if(this.gptResIng){
					// 提示客服正在努力回复中，请稍等再提问
					this.$refs.localToast.open()
					return
				}

				var messageText = this.gptMsg.trim()
				if (!!messageText) {
					this.gptResIng = true

					this.displayChatMessage("user", messageText);
					this.storeChatMessage("user", messageText);
					this.gptMsg = "";
					// 滚动到聊天窗底部
					this.scrollBottom()
					// gpt客服版本1 - 请求socket获取回答
					// this.socket.send(messageText);

					// gpt客服版本2 - 请求接口获取回答
					var gptData = {
						msg: messageText,
						user_name:'',
						web: '',
						currency: '',
					}
					if(this.account){
						gptData = {
							msg: messageText,
							user_name:this.account,
							web: getTradingPairInfo("web"),
							currency: getTradingPairInfo("yenUnit1") + (getTradingPairInfo("yenUnit2")? '_'+getTradingPairInfo("yenUnit2") : ''),
						}
					}
					axios.post('https://workflow.llkkz.net:3000/workflow/run',gptData).then(res => {
						// console.log('res',res)
						this.responseMsg(res.data.response)
					}).catch(err => {
						// console.log(err)
						this.gptResIng = false;
						this.displayChatMessage("assistant", this.$t('tip.gptErr'), 1);
						this.storeChatMessage("assistant", this.$t('tip.gptErr'), 1);
					})
					// gpt客服版本2 - 请求接口获取回答 - 本地调试
					// gptRequest(gptData).then(res=>{
					// 	// console.log(res.response)
					// 	this.responseMsg(res.response)
					// }).catch(err=>{
					// 	// console.log(err)
					// 	this.responseMsg(err.response)
					// })
				}
			},
			// 版本2-机器人回答
			responseMsg(msg) {
				if(this.timer){
					clearInterval(this.timer);
				}
				// console.log(msg)
				this.gptResIng = false;
				if(msg){
					this.storeChatMessage("assistant", msg);
					// 逐字效果
					var nowStrIndex = 0;
					this.timer = setInterval(() => {
						var nowStr = msg.slice(nowStrIndex, nowStrIndex + 1)
						this.displayChatMessage("assistant", nowStr);
						if(nowStrIndex == msg.length - 1){
							clearInterval(this.timer);
							this.scrollBottom()
						}
						nowStrIndex=nowStrIndex + 1
					}, 50);

					this.$once("hook:destroyed", () => {
						if (this.timer) {
							clearInterval(this.timer);
						}
					});
				}
			},
			// 滚动到聊天窗底部
			scrollBottom() {
				this.$nextTick(() => {
					const chatWindow = document.getElementById('gptDialogContainer');
					chatWindow.scrollTop = chatWindow.scrollHeight;
				});
			},

			// 拖拽
			handleMouseDown(event) {
				this.isMouseDown = true;
				this.isDragging = false;
				// 判断事件类型并获取坐标值
				let clientX, clientY;
				if (event.type === 'mousedown') {
					clientX = event.clientX;
					clientY = event.clientY;
				} else if (event.type === 'touchstart') {
					clientX = event.touches[0].clientX;
					clientY = event.touches[0].clientY;
				}
				this.mouseOffsetX = clientX - this.x;
				this.mouseOffsetY = clientY - this.y;
				this.mouseDownX = clientX;
				this.mouseDownY = clientY;
			},
			handleMouseMove(event) {
				// 判断事件类型并获取坐标值
				let clientX, clientY;
				if (event.type === 'mousemove') {
					clientX = event.clientX;
					clientY = event.clientY;
				} else if (event.type === 'touchmove') {
					clientX = event.touches[0].clientX;
					clientY = event.touches[0].clientY;
				}
				// chart拖拽
				if (this.isMouseDown) {
					const dx = Math.abs(clientX - this.mouseDownX);
					const dy = Math.abs(clientY - this.mouseDownY);
					if (dx > 5 || dy > 5) {
						this.isDragging = true;
						document.body.classList.add('no-select'); // 添加 no-select 类
					}
				}
				if (this.isDragging) {
					this.x = clientX - this.mouseOffsetX;
					this.y = clientY - this.mouseOffsetY;
				}

				// Kline、Opensea悬浮窗拖拽
				if (this.isMouseDown2) {
					const dx2 = Math.abs(clientX - this.mouseDownX2);
					const dy2 = Math.abs(clientY - this.mouseDownY2);
					if (dx2 > 5 || dy2 > 5) {
						this.$store.commit('markets/SET_IS_DRAGGING', true)
						document.body.classList.add('no-select'); // 添加 no-select 类
					}
				}
				if (this.isDragging2) {
					this.$store.commit('markets/SET_POSITION_X', clientX - this.mouseOffsetX2)
					this.$store.commit('markets/SET_POSITION_Y', clientY - this.mouseOffsetY2)
				}
			},
			handleMouseUp() {
				if (this.isMouseDown) {
					if (this.isDragging) {
						// 在这里执行拖拽结束操作
						this.isDragging = false;
						this.isMouseDown = false;
					} else {
						// 在这里执行点击操作
						this.isMouseDown = false;
						this.openChat();
					}
				}
				document.body.classList.remove('no-select'); // 移除 no-select 类
			},
			// 更新页面宽高
			updatePageWidthOrHeight() {
				// 重新定位移动端位置
				this.x = this.x / this.pageWidth * window.innerWidth
				this.y = this.y / this.pageHeight * window.innerHeight
				if (this.x < 0) this.x = 0
				if (this.x > window.innerWidth) this.x = window.innerWidth
				if (this.y < 0) this.y = 0
				if (this.y > window.innerHeight) this.y = window.innerHeight
				// 更新页面宽高
				this.pageWidth = window.innerWidth
				this.pageHeight = window.innerHeight
			},
		},
		watch: {
			// 主题切换
			theme: (now) => {
				document.getElementsByTagName('html')[0].setAttribute('data-theme', that.theme)
			},
			path: function (now, old) {
				// console.log(old+'=>'+now)
				if (old == '/stocks') {
					// 清除手动操盘
					localStorage.setItem('stocksIsFull', '0')
				}
				this.compareVersion()
			},
		},
	})
</script>

<style type="text/css">
	@import url("~@/assets/font/iconfont.css");
</style>
<style lang="stylus">
	.iframe-mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 999;//应大于iframe悬浮窗的index
	}

	/* 拖拽过程中禁止选中文字和图片 */
	.no-select {
		user-select: none;
	}

	/* gpt客服 */
	.gptBtn {
		position: fixed;
		z-index: 10000;
		width: 56px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
		transform: translate(-50%, -50%);
		overflow: hidden;
		background-color: #75A99C;
		background-image: url('./assets/images/chart.png');
		background-repeat: no-repeat;
		background-size: 88% 88%;
		background-position: center center;
	}

	.gptHiden i {
		transform: rotate(180deg)
	}

	.gptDialog {
		background: linear-gradient(to bottom right, #1a1a2e, #16213e);
		box-shadow: rgb(0 0 0 / 24%) 0px 0px 8px 0px;
		border-radius: 4px;
		position: fixed;
		z-index: 10000;
		width: 480px;
		height: 540px;
		bottom: 0px;
		right: 20px;
		display: flex;
		flex-direction: column;
		color: #DDE1EB;

		&.fullscreen {
			width: 100%;
			height: 100%;
			right: 0px;
		}

		@media screen and (max-width: 550px) {
			width: 100%;
			height: 100%;
			right: 0px;
		}

		.gptDialogTitle {
			padding 12px 10px 12px 20px;
			font-size 14px;
			display flex;
			align-items center;
			justify-content space-between;
			border-bottom 1px solid var(--border3);

			i {
				margin-right 4px;
				font-size 18px;
			}

			>div {
				display: flex;
				align-items: center;

				.clear {
					border: 1px solid var(--border3);
					border-radius: 12px;
					padding: 2px 8px;
					font-size: 12px;
					cursor pointer;
					margin-right: 4px;
				}

				.close {
					cursor pointer;
					font-size 16px;
					padding 0 10px;
					text-align center;
					margin-bottom: 2px;
				}
			}
		}

		.gptDialogContainer {
			padding 20px;
			display flex; // flex-direction column-reverse;
			flex-direction column; // justify-content: flex-end;
			flex-grow: 1;
			overflow-y: auto;

			>div {
				margin-bottom: 10px;

				display flex;

				&:first-child {
					margin-top: auto;
				}

				>span {
					padding: 8px 14px;
					border-radius: 12px;
					word-wrap: break-word;
					white-space: pre-wrap;
					max-width: 99%;
					box-sizing: border-box
				}

				&.user {
					justify-content flex-end;

					>span {
						background-color: #1c8cff;
					}
				}

				&.assistant {
					span,.typingIndicator {
						background-color: #16213e; //4a69bd
					}
					.errText{
						background-color: rgba(249,58,55,0.3)
					}
					
					.typingIndicator{
						display: flex;

						align-items: center;
						width: 47px;
						height: 32px;
						position: relative;

						>span {
							padding: 0;
							// width: 8px;
							// height: 8px;
							// margin: 0 4px;
							background-color: #3498db;
							border-radius: 50%;
							animation: blink 1.4s infinite;
							
							width: 4px;
							height: 4px;
							position: absolute;
							left: 14px;
						}
						>span:nth-child(2) {
							left: 21px;
							animation-delay: 0.2s;
						}

						>span:nth-child(3) {
							left: 28px;
							animation-delay: 0.4s;
						}

						@keyframes blink {
							// 0%, 80%, 100% {
							// 	opacity: 0;
							// }
							// 40% {
							// 	opacity: 1;
							// }
							0%, 100% {
								transform: translateY(0);
							}
							50% {
								transform: translateY(-3px);
							}
						}
					}
				}
			}
		}

		.gptInpBox {
			display flex;
			align-items center;
			padding 20px;
			background #16213e;
			width 100%;
			box-sizing border-box;

			.inp {
				flex-grow 1;

				.el-input__inner {
					background #fff;
					border-radius 8px;
					border none;
					color #333;
				}
			}

			.sendBtn {
				background: #1a1a2e;
				color: white;
				border: none;
				border-radius: 8px;
				padding: 8px 16px;
				cursor: pointer;
				margin-left: 10px;
				width 100px;
			}
		}
	}
</style>