const getters = {
    // user
    token: state => state.user.token,
    account: state => state.user.account,
    type: state => state.user.type,
    available_time: state => state.user.available_time,
    telegram: state => state.user.telegram,
    email: state => state.user.email,
    safe_login: state => state.user.safe_login,
    multi_login: state => state.user.multi_login,
    ip_check: state => state.user.ip_check,
    code: state => state.user.code,
    invite_url: state => state.user.invite_url,
    pay_set: state => state.user.pay_set,
    novice: state => state.user.novice,
    accountList: state => state.user.accountList,
    // 安全登录
    safe_account: state => state.user.safe_account,
    safe_email: state => state.user.safe_email,
    // markets
    ipWhitelist: state => state.markets.ipWhitelist,//ip白名单
    webList: state => state.markets.webList,
    // webListAll: state => state.markets.webListAll,
    collectList: state => state.markets.collectList,
    vipList: state => state.markets.vipList,
    solWebList: state => state.markets.solWebList,
    // 拖拽
    showKlineOrOpensea: state => state.markets.showKlineOrOpensea,
    positionX2: state => state.markets.positionX2,
    positionY2: state => state.markets.positionY2,
    isMouseDown2: state => state.markets.isMouseDown2,
    isDragging2: state => state.markets.isDragging2,
    mouseOffsetX2: state => state.markets.mouseOffsetX2,
    mouseOffsetY2: state => state.markets.mouseOffsetY2,
    mouseDownX2: state => state.markets.mouseDownX2,
    mouseDownY2: state => state.markets.mouseDownY2,
    // robot
    cexRobotList1: state => state.robot.cexRobotList1,
    cexRobotList2: state => state.robot.cexRobotList2,
    cexRobotList4: state => state.robot.cexRobotList4,
    robotListDex: state => state.robot.robotListDex,
    robotListNft: state => state.robot.robotListNft,

    // app
    isCollapse: state => state.app.isCollapse,
    device: state => state.app.device,
    device2: state => state.app.device2,
    lang: state => state.app.lang,
    isSmall: state => state.app.isSmall,
    isMobile: state => state.app.isMobile,
    theme: state => state.app.theme,

    version: state => state.app.version,
    isTwitter: state => state.app.isTwitter,
  }
  export default getters
  