<template>
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[3]}}</span>
			</div>
			
			<div class="content">
				<div class="content-container">
					<div class="robot_log">
						<div class="head">
							<div class="item">
								<div class="title">{{$t('common.selectdate')}}</div>
								<el-date-picker
									v-if="isSmall=='normal'"
									class="datetime"
									v-model="dateValue"
									type="datetimerange"
									range-separator="-"
									:start-placeholder="$t('common.startdete')"
									:end-placeholder="$t('common.enddete')"
									value-format="timestamp"
									:clearable="false"
									@change="selectTime"
								/>
								<!-- 如果小屏分为两个日期时间选择器 -->
								<div class="datetime_box" v-else>
									<el-date-picker
										v-model="start_time"
										class="datetime_item left"
										type="datetime"
										:placeholder="$t('common.startdete')"
										value-format="timestamp"
										:picker-options="maxTime"
										:clearable="false"
										@change="selectTimeOne">
									</el-date-picker>
									<span class="line">-</span>
									<el-date-picker
										v-model="end_time"
										class="datetime_item right"
										type="datetime"
										:placeholder="$t('common.enddete')"
										value-format="timestamp"
										:picker-options="minTime"
										:clearable="false"
										@change="selectTimeOne">
									</el-date-picker>
								</div>
							</div>
							<div class="item">
								<div class="title">{{$t('common.exchange')}}</div>
								<!-- {{$t('common.exchange')}} -->
								<div class="select" @click="openPopExchange">
									<input v-model="exchangeValue" type="text" :placeholder="$t('common.select')" readonly class="inp" />
									<i class="iconfont icon-down"></i>
								</div>
							</div>
							<div class="item" v-if="web!='opensea'">
								<div class="title">{{$t('common.pair')}}</div>
								<!-- {{$t('common.pair')}} -->
								<div class="inp_box">
									<input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
									<i class="split"></i>
									<input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
								</div>
							</div>
							<div class="item" v-else>
								<div class="title">{{$t('common.opensea')}}</div>
								<div class="inp_box">
									<input v-model="yenUnit1" type="text" :placeholder="$t('common.opensea')" class="inp" />
								</div>
							</div>
							<div class="item">
								<div class="title">{{$t('common.search')}}</div>
								<!-- {{$t('common.search')}} -->
								<el-input
									class="search"
									:placeholder="$t('common.keyword')"
									suffix-icon="el-icon-search"
									v-model="searchValue"
								/>
							</div>
							<div class="btn" @click="handleSearch">{{$t('common.query')}}</div>
							<div class="btn" @click="openDowenDialog">{{$t('common.downLoad')}}</div>
						</div>
						<div class="cont">
							<div class="cont_box">
								<template v-if="logList.length">
									<div class="list"
									v-infinite-scroll="load"
									:infinite-scroll-distance="10"
									:infinite-scroll-delay="400"
									:infinite-scroll-disabled="load_disabled">
										<template v-if="logList.length>0">
											<div class="item" v-for="(item,index) in logList" :key="index">
												<span class="time">{{item.time}}</span>
												<span class="exchange">{{item.web}}</span>
												<span class="unit">{{item.currency}}</span>

												<span class="text" v-html="item.content.replace(/[\n]/g,'<br/>')"></span>
											</div>
										</template>
									</div>
								</template>
								<el-empty v-else :image="emptyImg" :image-size="120" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="robot_log_phone">
					<div class="head">
						<el-input
							class="search"
							:placeholder="$t('common.keyword')"
							prefix-icon="el-icon-search"
							v-model="searchValue"
							type="search"
							@change="handleSearch2('keywords')"
						/>
						<!-- <div class="g_btn btn" @click="handleSearch">{{$t('common.query')}}</div> -->
						<div class="g_btn btn" @click="openDowenDialog">{{$t('common.downLoad')}}</div>
					</div>
					<div class="cont">
						<div class="cont_box">
							<template v-if="logList.length">
								<div class="list"
								v-infinite-scroll="load"
								:infinite-scroll-distance="10"
								:infinite-scroll-delay="400"
								:infinite-scroll-disabled="load_disabled">
									<template v-if="logList.length>0">
										<div class="item" v-for="(item,index) in logList" :key="index">
											<div>
												<div class="label">{{$t('common.time')}}</div>
												<div class="value">{{item.time}}</div>
											</div>

											<div>
												<div class="label">{{$t('common.exchange')}}</div>
												<div class="value">{{item.web}}</div>
											</div>

											<div>
												<div class="label">{{$t('common.pair')}}</div>
												<div class="value">{{item.currency}}</div>
											</div>

											<div>
												<div class="label">{{$t('common.content')}}</div>
												<div class="value" v-html="item.content.replace(/[\n]/g,'<br/>')"></div>
											</div>
										</div>
									</template>
								</div>
							</template>
							<el-empty v-else :image="emptyImg" :image-size="120" />
						</div>
					</div>
				</div>
			</div>
			
			<!-- 移动端-筛选条件弹窗 -->
			<van-action-sheet 
			v-model="filter_show" 
			:title="$t('common.filter')"
			:closeable="false">
				<div class="filter_form">
					<div class="item">
						<div class="title">{{$t('common.startdete')}}</div>
						<div class="text" @click="openDateTime('start')">
							<input v-model="start_time" type="text" :placeholder="$t('common.select')" class="inp" readonly />
						</div>
					</div>
					<div class="item">
						<div class="title">{{$t('common.enddete')}}</div>
						<div class="text" @click="openDateTime('end')">
							<input v-model="end_time" type="text" :placeholder="$t('common.select')" class="inp" readonly />
						</div>
					</div>
					<div class="item">
						<div class="title">{{$t('common.exchange')}}</div>
						<div class="text">
							<div class="select" @click="openPopExchange">
								<input v-model="exchangeValue" type="text" :placeholder="$t('common.select')" readonly class="inp" />
								<i class="iconfont icon-down"></i>
							</div>
						</div>
					</div>
					<div class="item" v-if="web!='opensea'">
						<div class="title">{{$t('common.pair')}}</div>
						<div class="inp_box">
							<input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
							<i class="split"></i>
							<input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
						</div>
					</div>
					<div class="item" v-else>
						<div class="title">{{$t('common.opensea')}}</div>
						<div class="inp_box">
							<input v-model="yenUnit1" type="text" :placeholder="$t('common.opensea')" class="inp" />
						</div>
					</div>
					<div class="g_dialogBtns">
						<div class="g_btn btn" @click="filter_show = false">{{$t('common.back')}}</div>
						<div class="g_btn btn submit" @click="handleSearch2">{{$t('common.query')}}</div>
					</div>
				</div>
			</van-action-sheet>
			<!-- 移动端-时间选择器 -->
			<van-popup v-model="dateTimeBox" round position="bottom">
				<van-datetime-picker
				ref="datetimePicker"
				v-model="currentDate"
				type="datetime"
				:title="$t('common.selectTime')"
				:formatter="formatter"
				visible-item-count="4"
				@confirm="setTime"
				:min-date="min_date"
				:max-date="max_date"
				/>
			</van-popup>
		</template>

		<!-- 交易所选择 -->
		<exchange-dialog
			:show="popExchange"
			:web="dialogWeb"
			@close="closeExchange"
			@select="handleSelect"
		/>

		<!-- 导出弹窗 -->
		<el-dialog :title="$t('common.downLoad')" :visible.sync="showExcelDowen">
			<div class="dialogFormBox">
				<div class="item">
					<div class="title">{{$t('common.selectdate')}}</div>
					<el-date-picker
						v-if="isSmall=='normal'"
						class="datetime"
						v-model="down_dateValue"
						type="datetimerange"
						range-separator="-"
						:start-placeholder="$t('common.startdete')"
						:end-placeholder="$t('common.enddete')"
						value-format="timestamp"
						:clearable="false"
						@change="selectTime2"
						@blur="initPickerDate"
						:picker-options="down_pickerOptions"
					/>
					<!-- 如果小屏分为两个日期时间选择器 -->
					<div class="datetime_box" v-else>
						<el-date-picker
							v-model="down_start_time"
							class="datetime_item left"
							type="datetime"
							:placeholder="$t('common.startdete')"
							value-format="timestamp"
							:picker-options="down_maxTime"
							:clearable="false"
							@change="selectTimeOne2">
						</el-date-picker>
						<span class="line">-</span>
						<el-date-picker
							v-model="down_end_time"
							class="datetime_item right"
							type="datetime"
							:placeholder="$t('common.enddete')"
							value-format="timestamp"
							:picker-options="down_minTime"
							:clearable="false"
							@change="selectTimeOne2">
						</el-date-picker>
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.exchange')}}</div>
					<div class="select" @click="openPop">
						<input v-model="down_exchangeValue" type="text" :placeholder="$t('common.select')" readonly class="inp" />
						<i class="iconfont icon-down"></i>
					</div>
				</div>
				<div class="item" v-if="web!='opensea'">
					<div class="title">{{$t('common.pair')}}</div>
					<div class="inp_box">
						<input v-model="down_yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
						<i class="split"></i>
						<input v-model="down_yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
					</div>
				</div>
				<div class="item" v-else>
					<div class="title">{{$t('common.opensea')}}</div>
					<div class="inp_box">
						<input v-model="down_yenUnit1" type="text" :placeholder="$t('common.opensea')" class="inp" />
					</div>
				</div>

				<div class="g_dialogBtns">
					<div class="btn" @click="showExcelDowen=false">{{$t('common.back')}}</div>
					<div class="btn submit" @click="excelDowen">{{$t('common.downLoad')}}</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'robot.styl';
	@import 'log.styl';
</style>

<script>
	import { Loading } from 'element-ui';

	import ExchangeDialog from '@/components/exchange.vue';
	import { mapGetters } from 'vuex';
	import bus from "../bus.js";
	import timeFormat from '@/utils/methApi/timeFormat'
    import { getTradingPairInfo } from '@/utils/methApi/tradingPairDefault'
	// api
	import { getLog,sendLog } from '@/api/robot';
	export default ({
		name: 'robotlog',
		components: {
			ExchangeDialog,
		},
		data() {
			return {
				emptyImg: require('@/assets/images/loading.png'),
				
				logList: [],
				dateValue: [],
				start_time:'',
				end_time:'',
				exchangeValue: getTradingPairInfo('exchange'),
				web: getTradingPairInfo('web'),
				searchValue: null,
				yenUnit1: getTradingPairInfo('yenUnit1'),
				yenUnit2: getTradingPairInfo('yenUnit2'),
				// 交易所选择
				popExchange: false,
				dialogWeb:'',
				isSonPop:false,

				page:1,
				load_disabled:false,

				isFirst:'1',//是否是第一次进入该页面

				// 无限滚动-固化变量
				req_start_time:'',
				req_end_time:'',
				req_web:'',
				req_yenUnit1:'',
				req_yenUnit2:'',
				req_searchValue:'',
				// 移动端
				filter_show:false,
				//时间选择器
				dateTimeBox:false,
				currentDate:new Date(),
				type:'',//时间类型 开始时间/结束时间

				// 导出日志弹窗
				showExcelDowen: false,
				down_dateValue:[],
				down_pickDate:'',
				down_start_time:'',
				down_end_time:'',
				down_exchangeValue:'',
				down_web:'',
				down_yenUnit1:'',
				down_yenUnit2:'',
				down_pickerOptions:{
					onPick: (pick) => {
						this.down_pickDate = pick; //获取当前选择日期
					},
					disabledDate: (date) => {
						const { minDate, maxDate } = this.down_pickDate;
						if (date.getTime() > Date.now()+86400000) {
							//选择今天及以前的日期
							return true;
						} else if (minDate && !maxDate) {
							// 日期范围控制在7天内
							const tamp = Math.abs(minDate.valueOf() - date.valueOf());
							const customTimeStamp = 604800000;
							if (tamp > customTimeStamp) {
								return true;
							}
						}
					},
				}
			}
		},
		computed:{
			...mapGetters(['account','isSmall','isMobile']),
			// 开始时间
			maxTime() {
				return {
					disabledDate:(time)=>{
						if (this.end_time) { // 如果结束时间不为空，则小于结束时间
							return time.getTime() > this.end_time
						}
					}
				}
			},
			// 结束时间
			minTime() {
				return {
					disabledDate:(time)=>{
						if (this.start_time) { // 如果开始时间不为空，则结束时间大于等于开始时间
							return time.getTime() < this.start_time
						}
					}
				}
			},
			
			// 开始时间
			down_maxTime() {
				return {
					disabledDate:(time)=>{
						if (this.down_end_time) { // 如果结束时间不为空，则小于结束时间、小于7天、小于今天
							return time.getTime() > this.down_end_time || time.getTime()<(this.down_end_time-604800000) || time.getTime() > new Date().getTime()+86400000
						}else{
							return time.getTime() > new Date().getTime()+86400000
						}
					}
				}
			},
			// 结束时间
			down_minTime() {
				return {
					disabledDate:(time)=>{
						if (this.down_start_time) { // 如果开始时间不为空，则结束时间大于等于开始时间
							return time.getTime() < this.down_start_time || time.getTime()>(this.down_start_time+604800000) || time.getTime() > new Date().getTime()+86400000
						}
						else {
							return time.getTime() > new Date().getTime()+86400000
						}
					}
				}
			},
			
			// 移动端
			min_date(){
				var min_date
				if(this.type=='start'){
					min_date=new Date(0)
				}else{
					min_date=this.start_time?new Date(this.start_time.replace(/\-/g,'/')):new Date(0)
				}
				return min_date
			},
			max_date(){
				var max_date
				if(this.type=='start'){
					max_date=this.end_time?new Date(this.end_time.replace(/\-/g,'/')):new Date()
				}else{
					max_date=new Date()
				}
				return max_date
			},
		},
		created(){
			this.$nextTick(()=>{
				if(!!this.$route.query.web){
					this.exchangeValue=this.$route.query.exchange;
					this.web=this.$route.query.web;
					this.yenUnit1='';
					this.yenUnit2='';
					this.searchValue=this.$route.query.searchValue;
				}
				this.getLog();
			});

        	// 打开筛选弹窗-移动端判定
			bus.$on('filter',()=>{
				this.filter_show=true
			})
		},
		methods: {
			initPickerDate(){
				this.down_pickDate='';
			},
			// 打开导出日志弹窗
			openDowenDialog(){
				// init
				this.down_dateValue=this.dateValue
				this.down_start_time=this.start_time
				this.down_end_time=this.end_time
				this.down_exchangeValue=this.exchangeValue
				this.down_web=this.web
				this.down_yenUnit1=this.yenUnit1
				this.down_yenUnit2=this.yenUnit2

				this.showExcelDowen=true;
			},
			// 导出日志
			excelDowen(){
				var start_time,end_time;
				if(this.isSmall=='small'){
					// 日期没问题 时间选择时 结束时间比开始时间小
					if(this.down_start_time>this.down_end_time){
						start_time=this.down_end_time||'';
						end_time=this.down_start_time||'';
					}else{
						start_time=this.down_start_time||'';
						end_time=this.down_end_time||'';
					}
				}else{
					if(this.down_dateValue[0]>this.down_dateValue[1]){
						start_time=this.down_dateValue&&this.down_dateValue[1]?this.down_dateValue[1]:'';
						end_time=this.down_dateValue&&this.down_dateValue[0]?this.down_dateValue[0]:'';
					}else{
						start_time=this.down_dateValue&&this.down_dateValue[0]?this.down_dateValue[0]:'';
						end_time=this.down_dateValue&&this.down_dateValue[1]?this.down_dateValue[1]:'';
					}
				}

				var currency;
				if(this.web!='opensea'){
					currency=this.down_yenUnit1&&this.down_yenUnit2?this.down_yenUnit1+'_'+this.down_yenUnit2:''
				}else{
					currency=this.down_yenUnit1
				}
				
				if(!start_time||!end_time){
					this.$message({
						message: this.$t('tip.selectTimeTip'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(end_time-start_time>604800000){
					this.$message({
						message: this.$t('tip.rangeErr'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.down_web){
					this.$message({
						message: this.$t('tip.selectExchange'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!currency){
					this.$message({
						message: this.$t('tip.enterPair'),
						center: true,
						type: 'error',
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					this.$confirm(this.$t('robot.downLoadTip'),this.$t('tip.tips'),{
						confirmButtonText: this.$t('common.confirm'),
						cancelButtonText: this.$t('common.cancel'),
						center: true,
						customClass: 'deleteOk',
						showClose:false,
					}).then(() => {
						var data={
							account:this.account,
							start_time,
							end_time,
							web:this.down_web,
							currency,
						}
						const loading = Loading.service({
							text: 'Loading',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)',
							customClass: 'apploading',
						});
						sendLog(data).then(res=>{
							this.$message({
								message: this.$t('tip.sendSuc'),
								center: true,
								type: 'success',
								customClass: 'shotMsg',
							});
							this.showExcelDowen=false;
							loading.close();
						}).catch(err=>{
							loading.close();
						})
					})
				}
			},
			// 时间日期选择区间
			selectTime2(values){
				if(values){
					this.down_start_time=values[0]
					this.down_end_time=values[1]
				}
			},
			// 时间日期选择区间-小屏
			selectTimeOne2(){
				if(this.down_start_time&&this.down_end_time){
					this.down_dateValue=[this.down_start_time,this.down_end_time]
				}
			},

			// 时间日期选择区间-小屏
			selectTimeOne(){
				if(this.start_time&&this.end_time){
					this.dateValue=[this.start_time,this.end_time]
				}
			},
			// 时间日期选择区间
			selectTime(values){
				if(values){
					this.start_time=values[0]
					this.end_time=values[1]
				}
			},
			// 加载更多
			load(){
				// console.log('get-load')
				this.page++
				this.getLog();
			},
			getLog(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				if(this.isFirst=='1'){
					var data={
						account:this.account,
						page:this.page,
						web:'',
						currency:'',
						start_time:'',
						end_time:'',
						keywords:'',
					}
				}else{
					// 无限滚动-固化变量-获取log
					var start_time,end_time;
					if(this.isMobile==1){//移动端判定
						if(this.req_start_time&&this.req_end_time){
							var dateValue1=new Date(this.req_start_time?.replace(/\-/g,'/')).getTime()
							var dateValue2=new Date(this.req_end_time?.replace(/\-/g,'/')).getTime()
							if(dateValue1>dateValue2){
								start_time=this.req_end_time;
								end_time=this.req_start_time;
							}else{
								start_time=this.req_start_time
								end_time=this.req_end_time
							}
						}else{
							start_time=this.req_start_time
							end_time=this.req_end_time
						}
					}
					else if(this.isSmall=='small'){
						// 日期没问题 时间选择时 结束时间比开始时间小
						if(this.req_start_time>this.req_end_time){
							start_time=this.req_end_time?timeFormat(this.req_end_time,'dateTime'):'';
							end_time=this.req_start_time?timeFormat(this.req_start_time,'dateTime'):'';
						}else{
							start_time=this.req_start_time?timeFormat(this.req_start_time,'dateTime'):'';
							end_time=this.req_end_time?timeFormat(this.req_end_time,'dateTime'):'';
						}
					}else{
						if(this.req_dateValue[0]>this.req_dateValue[1]){
							start_time=this.req_dateValue&&this.req_dateValue[1]?timeFormat(this.req_dateValue[1],'dateTime'):'';
							end_time=this.req_dateValue&&this.req_dateValue[0]?timeFormat(this.req_dateValue[0],'dateTime'):'';
						}else{
							start_time=this.req_dateValue&&this.req_dateValue[0]?timeFormat(this.req_dateValue[0],'dateTime'):'';
							end_time=this.req_dateValue&&this.req_dateValue[1]?timeFormat(this.req_dateValue[1],'dateTime'):'';
						}
					}

					var currency;
					if(this.web!='opensea'){
						currency=this.req_yenUnit1&&this.req_yenUnit2?this.req_yenUnit1+'_'+this.req_yenUnit2:''
					}else{
						currency=this.req_yenUnit1
					}

					var data={
						account:this.account,
						page:this.page,
						web:this.req_web?this.req_web:'',
						currency,
						start_time,
						end_time,
						keywords:this.req_searchValue?this.req_searchValue:'',
					}
				}

				getLog(data).then(res=>{
					// console.log(res)
					if(!!res&&res.length>0){
						res.forEach(item => {
							try {
								if(item.content.includes("wait_parse")){
									var detail = JSON.parse(item.content)
									// 如果是分拨归集进度则返回的是json字符串且wait_parse为true
									if(detail.wait_parse==true){
										item.content=unescape(detail.content.replace(/\\(u[0-9a-fA-F]{4})/gm, '%$1'))
									}else{
										throw Error()
									}
								}
							} catch (error) {
								item.content=unescape(item.content.replace(/\\(u[0-9a-fA-F]{4})/gm, '%$1'))
							}
						});
						this.logList.push(...res);
						// this.logList=res
					}else{
						this.load_disabled=true;
					}
					loading.close();
				}).catch(err=>{
					loading.close();
				})
			},
			// 搜索
			handleSearch() {
				// 初始化
				this.page=1;
				this.load_disabled=false;
				this.logList=[];
				this.isFirst='0';

				// 无限滚动-固化变量
				this.req_dateValue=JSON.parse(JSON.stringify(this.dateValue))
				this.req_start_time=this.start_time
				this.req_end_time=this.end_time
				this.req_web=this.web
				this.req_yenUnit1=this.yenUnit1
				this.req_yenUnit2=this.yenUnit2
				this.req_searchValue=this.searchValue
				
				// 搜索
				this.getLog();
			},
			// 交易所
			openPopExchange(){
				this.dialogWeb=this.web
				this.popExchange=true
			},
			openPop(){
				this.dialogWeb=this.down_web
				this.isSonPop=true
				this.popExchange=true
			},
			closeExchange() {
				this.popExchange = false;
				// 子组件判定初始化
				if (this.isSonPop) {
					this.isSonPop = false;
				}
			},
			handleSelect(data) {
				if (this.isSonPop) {
					this.down_exchangeValue = data.text;
					this.down_web = data.web;
				}else{
					this.exchangeValue = data.text;
					this.web = data.web;
				}
				this.popExchange = false;
			},
			
			// 打开时间选择器
			openDateTime(type){
				this.type=type
				if(type=='start'){
					this.currentDate=this.start_time||new Date()
				}else{
					this.currentDate=this.end_time||new Date()
				}
				this.dateTimeBox=true;
			},
			// 时间选择器视图
			formatter(type, val) {
				if (type === 'year') {
					return val + '年';
				}
				if (type === 'month') {
					return val + '月';
				}
				if (type === 'day') {
					return val + '日';
				}
				if (type === 'hour') {
					return val + '时';
				}
				if (type === 'minute') {
					return val + '分';
				}
				return val;
			},
			setTime(e){
				if(this.type=='start'){
					this.start_time=timeFormat(e,'dateTime')
				}else{
					this.end_time=timeFormat(e,'dateTime')
				}
				this.dateTimeBox=false
			},
			// 移动端-筛选确定/关键词搜索
			handleSearch2(type) {
				if(type!='keywords'){
					this.req_start_time=this.start_time
					this.req_end_time=this.end_time
					this.req_web=this.web
					this.req_yenUnit1=this.yenUnit1
					this.req_yenUnit2=this.yenUnit2
				}else{
					this.req_searchValue=this.searchValue
				}
				// 初始化
				this.page=1;
				this.load_disabled=false;
				this.logList=[];
				this.isFirst='0';
				this.filter_show=false

				// 搜索
				this.getLog();
			},
		}
	})
</script>
