<template>
    <!-- 交易记录 -->
	<div class="container">
        <template v-if="isMobile==0">
            <div class="header">
                <router-link class="btn" to="/stocks">{{$t('aside.list')[1]}}</router-link>
                <i class="iconfont2 icon-right_arrow"></i>
                <span>{{$t('common.transactionRecord')}}</span>
            </div>
            <div class="content">
                <div class="head">
                    <!-- 交易所交易对 -->
                    <div class="item">
                        <div class="title">{{$t('common.selectOpen')}}</div>
                        <div class="select" @click="openPopCollect">
                            <input v-model="open" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                            <i class="el-icon-arrow-down"></i>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.selectdate')}}</div>
                        <el-date-picker
                            v-if="isSmall=='normal'"
                            :editable="false"
                            class="datetime"
                            v-model="inp_dateValue"
                            type="datetimerange"
                            range-separator="-"
                            :start-placeholder="$t('common.startdete')"
                            :end-placeholder="$t('common.enddete')"
                            value-format="timestamp"
                            :clearable="false"
                            @change="selectTime"
                            :picker-options="isDisabled"
                        />
                        <!-- 如果小屏分为两个日期时间选择器 -->
                        <div class="datetime_box" v-else>
                            <el-date-picker
                                :editable="false"
                                v-model="inp_start_time"
                                class="datetime_item left"
                                type="datetime"
                                :placeholder="$t('common.startdete')"
                                value-format="timestamp"
                                :picker-options="maxTime"
                                :clearable="false">
                            </el-date-picker>
                            <span class="line">-</span>
                            <el-date-picker
                                :editable="false"
                                v-model="inp_end_time"
                                class="datetime_item right"
                                type="datetime"
                                :placeholder="$t('common.enddete')"
                                value-format="timestamp"
                                :picker-options="minTime"
                                :clearable="false">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.tradingDirection')}}</div>
                        <el-select class="inp" v-model="inp_direction" :placeholder="$t('common.select')">
                            <el-option
                                :label="$t('common.whole')"
                                :value="2">
                            </el-option>
                            <el-option
                                :label="$t('common.purchase')"
                                :value="1">
                            </el-option>
                            <el-option
                                :label="$t('common.sellout')"
                                :value="0">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.address')}}</div>
                        <div class="text">
                            <el-input
                                class="inp"
                                :placeholder="$t('common.address')"
                                v-model="inp_address"
                            />
                        </div>
                        <!-- <el-select class="inp" v-model="inp_address" :placeholder="$t('common.select')">
                            <el-option
                                :label="$t('common.address')"
                                :value="0">
                            </el-option>
                        </el-select> -->
                    </div>
                    <div class="btn" @click="handleSearch">{{$t('common.query')}}</div>
                    <div class="btn" @click="exportExcel">{{$t('common.downLoad')}}</div>
                </div>

                <div class="cont">
                    <div class="caption">
                        {{$t('common.transactionRecord')}}
                        <span class="tip">({{$t('tip.recordListTip')}})</span>
                    </div>
                    <div class="cont_box">
                        <div class="hd">
                            <span class="address">{{$t('common.walletAddress')}}</span>
                            <!-- <span class="pair">{{$t('common.pair')}}</span> -->
                            <span class="direction">{{$t('common.tradingDirection')}}</span>
                            <span class="average" v-if="web_type==0">{{$t('common.transactionAverage')}}</span>
                            <span class="num">{{$t('common.transactionNum')}}</span>
                            <!-- <span class="amount">{{$t('common.transactionAmount')}}</span> -->
                            <span class="gas">{{$t('common.gas')}}</span>
                            <span class="state">{{$t('common.orderStatus')}}</span>
                            <span class="time">{{$t('common.time')}}</span>
                            <span class="operation" v-if="web_type==1">{{$t('common.viewTX')}}</span>
                        </div>
                        <div class="bd">
                            <template v-if="recordList.length">
                                <div class="list"
                                v-infinite-scroll="load"
                                :infinite-scroll-distance="10"
                                :infinite-scroll-delay="400"
                                :infinite-scroll-disabled="load_disabled">
                                    <div class="item" v-for="(item,index) in recordList" :key="index">
                                        <span class="address">
                                            <el-tooltip effect="dark" :content="$t('subManage.copyAddress')" placement="top" :enterable="false">
                                                <i class="iconfont2 icon-jilu1 copyBtn" @click="handleCopy(item.wallet_address)"></i>
                                            </el-tooltip>
                                            <el-tooltip effect="dark" :content="item.wallet_address" placement="top" :enterable="false">
                                                <span>{{addressFormat(item.wallet_address)}}</span>
                                            </el-tooltip>
                                        </span>
                                        <!-- <span class="pair">{{item.pair}}</span> -->
                                        <span class="direction" :class="{red:item.trade_type=='0'}">{{
                                            item.trade_type=='0'? $t('common.sellout'):   
                                            item.trade_type=='1'? $t('common.purchase'):item.trade_type
                                        }}</span>
                                        <span class="average" v-if="web_type==0">
                                            {{item.price}} 
                                            <span class="unit" :class="{red:item.trade_type=='0'}">{{item.price_unit}}</span>
                                        </span>
                                        <span class="num">
                                            {{item.amount}} 
                                            <span class="unit" :class="{red:item.trade_type=='0'}">{{item.amount_unit}}</span>
                                        </span>
                                        <span class="gas">
                                            {{item.gas_use}} 
                                            <span class="unit gas_unit">{{item.gas_unit}}</span>
                                        </span>
                                        <span class="state">{{
                                            item.state==0? $t('common.orderStatus1'):
                                            item.state==1? $t('common.orderStatus2'):
                                            item.state==2? $t('common.orderStatus3'):item.state
                                        }}</span>
                                        <span class="time">{{item.order_time}}</span>
                                        <span class="operation" v-if="web_type==1">
                                            <div>
                                                <img class="chainIcon" v-if="chainIcon" :src="require('@/assets/images/chain/'+chainIcon)" alt="" @click="jumpOut(item.order_id)">
                                                <img v-else-if="theme=='light'" :src="require('@/assets/images/subManage/import_light.png')" alt="" @click="jumpOut(item.order_id)">
                                                <img v-else-if="theme=='dark'" :src="require('@/assets/images/subManage/import.png')" alt="" @click="jumpOut(item.order_id)">
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </template>
                            <el-empty v-else :image="emptyImg" :image-size="120" />
                        </div>
                    </div>
                </div>

            </div>
        </template>
		<template v-else-if="isMobile==1">
            <div class="content_phone">
                <div class="head">
                    <el-input
                        class="search"
                        :placeholder="$t('common.address')"
                        prefix-icon="el-icon-search"
                        v-model="address"
                        type="search"
                        @change="handleSearch2('keywords')"
                    />
                    <div class="g_btn btn" @click="exportExcel">{{$t('common.downLoad')}}</div>    
                </div>
                <div class="cont">
                    <div class="caption">
                        {{$t('common.transactionRecord')}}
                        <span class="tip">({{$t('tip.recordListTip')}})</span>
                    </div>
                    <div class="cont_box">
                        <template v-if="recordList.length">
                            <div class="list"
                            v-infinite-scroll="load"
                            :infinite-scroll-distance="10"
                            :infinite-scroll-delay="400"
                            :infinite-scroll-disabled="load_disabled">
                                <div class="item" v-for="(item,index) in recordList" :key="index">
                                    <div>
                                        <div class="label">{{$t('common.walletAddress')}}</div>
                                        <div class="value">
                                            {{item.wallet_address}}
                                        </div>
                                    </div>
                                    <div>
                                        <div class="label">{{$t('common.tradingDirection')}}</div>
                                        <div class="value" :class="item.trade_type=='0'?'red':'green'">{{
                                            item.trade_type=='0'? $t('common.sellout'):   
                                            item.trade_type=='1'? $t('common.purchase'):item.trade_type
                                        }}</div>
                                    </div>
                                    <div v-if="web_type==0">
                                        <div class="label">{{$t('common.transactionAverage')}}</div>
                                        <div class="value">
                                            {{item.price}} 
                                            <span class="unit" :class="item.trade_type=='0'?'red':'green'">{{item.price_unit}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="label">{{$t('common.transactionNum')}}</div>
                                        <div class="value">
                                            {{item.amount}} 
                                            <span class="unit" :class="item.trade_type=='0'?'red':'green'">{{item.amount_unit}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="label">{{$t('common.gas')}}</div>
                                        <div class="value">
                                            {{item.gas_use}} 
                                            <span class="unit gas_unit">{{item.gas_unit}}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="label">{{$t('common.orderStatus')}}</div>
                                        <div class="value">{{
                                            item.state==0? $t('common.orderStatus1'):
                                            item.state==1? $t('common.orderStatus2'):
                                            item.state==2? $t('common.orderStatus3'):item.state
                                        }}</div>
                                    </div>
                                    <div>
                                        <div class="label">{{$t('common.time')}}</div>
                                        <div class="value">
                                            {{item.order_time}}
                                        </div>
                                    </div>
                                    <div class="viewTX" v-if="web_type==1">
                                        <div class="label">{{$t('common.viewTX')}}</div>
                                        <div class="value">
                                            <div>
                                                <img class="chainIcon" v-if="chainIcon" :src="require('@/assets/images/chain/'+chainIcon)" alt="" @click="jumpOut(item.order_id)">
                                                <img v-else-if="theme=='light'" :src="require('@/assets/images/subManage/import_light.png')" alt="" @click="jumpOut(item.order_id)">
                                                <img v-else-if="theme=='dark'" :src="require('@/assets/images/subManage/import.png')" alt="" @click="jumpOut(item.order_id)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <el-empty v-else :image="emptyImg" :image-size="120" />
                    </div>
                </div>
            </div>
        </template>

		<!-- 选择盘口 常用盘口 -->
		<el-dialog class="collect" :title="$t('common.selectOpen')" :visible.sync="popCollection">
			<div class="manual_box">
                <div class="selectBox">
                    <div class="manualTitle">{{$t('stocks.selectExchange')}}</div>
                    <div class="select" @click="popExchange = true">
                        <input v-model="popexchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                        <i class="iconfont icon-down"></i>
                    </div>
                </div>
				<div class="pairBox">
					<div class="manualTitle">{{$t('stocks.enterPair')}}</div>
                    <div class="pair">
                        <input v-model="popyenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
                        <i class="split"></i>
                        <input v-model="popyenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
                    </div>
                </div>
            </div>
			<div class="foot">
				<span class="g_btn btn" @click="popCollection=false">{{$t('common.back')}}</span>
				<span class="g_btn btn submit" @click="handleInConfirm">{{$t('common.confirm')}}</span>
			</div>
			<div class="title">{{$t('common.commonly')}}</div>
			<div class="company_box">
				<div class="tab_title">
					<div class="left">{{$t('common.exchange')}}</div>
					<div class="right">{{$t('common.pair')}}</div>
				</div>
				<div class="item" v-for="(item,index) in collectList" :key="index" @click="commonlyTc(item)">
					<div class="left">{{item.exchange_name}}</div>
					<div class="right">
						<div>{{item.currency}}</div>
						<div class="collect_btn" @click.stop="collectCancel(item.currency,item.web_id)">
							<img src="@/assets/images/collection_active.png" alt="">
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
		<!-- 交易所选择 -->
		<exchange-dialog
			:show.sync="popExchange"
			:web="popweb"
            :notEl="true"
			@close="closeExchange"
			@select="handleSelect"
		/>
        
		<!-- 移动端-筛选条件弹窗 -->
        <van-action-sheet 
        v-model="filter_show" 
        :title="$t('common.filter')"
        :closeable="false">
			<div class="filter_form">
				<div class="item">
					<div class="title">{{$t('common.exchange')}}</div>
					<div class="text">
						<div class="select" @click="popExchange = true">
							<input v-model="vant_exchange_value" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont icon-down"></i>
						</div>
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.pair')}}</div>
					<div class="inp_box">
						<input v-model="vant_yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
						<i class="split"></i>
						<input v-model="vant_yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.startdete')}}</div>
					<div class="text" @click="openDateTime('start')">
						<input v-model="vant_start_time" type="text" :placeholder="$t('common.select')" class="inp" readonly />
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.enddete')}}</div>
					<div class="text" @click="openDateTime('end')">
						<input v-model="vant_end_time" type="text" :placeholder="$t('common.select')" class="inp" readonly />
					</div>
				</div>
                <div class="item">
                    <div class="title">{{$t('common.tradingDirection')}}</div>
					<div class="text">
						<div class="select" @click="directionShow = true">
							<input v-model="direction_name" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont icon-down"></i>
						</div>
					</div>
                </div>
				<div class="foot">
					<div class="g_btn btn" @click="closeAction">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="handleSearch2">{{$t('common.query')}}</div>
				</div>

			</div>
        </van-action-sheet>
		<!-- 移动端-时间选择器 -->
		<van-popup v-model="dateTimeBox" round position="bottom">
			<van-datetime-picker
			ref="datetimePicker"
			v-model="currentDate"
			type="datetime"
        	:title="$t('common.selectTime')"
			:formatter="formatter"
			visible-item-count="4"
			@confirm="setTime"
			:min-date="min_date"
			:max-date="max_date"
			/>
		</van-popup>
        <!-- 移动端-交易方向选择 -->
        <div class="direction">
            <van-action-sheet v-model="directionShow" :actions="actions" @select="directionSelect" />
        </div>
		<!-- 绑定邮箱 -->
		<emailBind ref="emailBind"></emailBind>
    </div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'record.styl';
</style>

<script>
import Clipboard from 'clipboard';
import { Loading } from 'element-ui';
import ExchangeDialog from '@/components/exchange.vue';
import emailBind from '@/components/emailBind.vue';

import { mapGetters } from 'vuex';
import bus from "../bus.js";
import timeFormat from '@/utils/methApi/timeFormat'
import addressFormat from '@/utils/methApi/addressFormat'
import { getTradingPairInfo } from '@/utils/methApi/tradingPairDefault'
// api
import {
    getDexOrders,
    getCexOrders
} from '@/api/markets'
import { exportRecordsSend } from '@/api/data'
export default ({
    components: {
        ExchangeDialog,
        emailBind,
    },
    data() {
        return {
			emptyImg: require('@/assets/images/loading.png'),

            // 交易记录列表
            recordList:[],
            chain:'',
            chain_url:'',


            // 常用盘口
            popCollection:false,
            // collectList:[],
            popexchange: getTradingPairInfo('exchange'),
            popweb: getTradingPairInfo('web'),
            popyenUnit1: getTradingPairInfo('yenUnit1'),
            popyenUnit2: getTradingPairInfo('yenUnit2'),
            popweb_type: getTradingPairInfo('web_type'),//交易所类型 0-cex、1-dex

            page:1,
			load_disabled:false,
            
            // 交易所选择
            popExchange: false,
            inp_web: getTradingPairInfo('web'),
            inp_exchange_value: getTradingPairInfo('exchange'),
            inp_yenUnit1: getTradingPairInfo('yenUnit1'),
            inp_yenUnit2: getTradingPairInfo('yenUnit2'),
            inp_web_type: getTradingPairInfo('web_type'),//交易所类型 0-cex、1-dex
			inp_dateValue:[],
            inp_start_time:'',
            inp_end_time:'',
			inp_address:'',
            inp_direction:2,

            // PC-无限滚动-固化变量
            web: getTradingPairInfo('web'),
            exchangeValue: getTradingPairInfo('exchange'),
            yenUnit1: getTradingPairInfo('yenUnit1'),
            yenUnit2: getTradingPairInfo('yenUnit2'),
            web_type: getTradingPairInfo('web_type'),//交易所类型 0-cex、1-dex
            dateValue: [],//时间集合
            start_time:'',//开始时间
            end_time:'',//结束时间
            address:'',// 地址
            direction:2,// 交易方向
            
            // 移动端
            filter_show:false,
            vant_web:'',
            vant_web_type:'',
            vant_yenUnit1:'',
            vant_yenUnit2:'',
            vant_start_time:'',
            vant_end_time:'',
            vant_exchange_value:'',
            vant_direction:2,
            //时间选择器
            dateTimeBox:false,
            currentDate:new Date(),
            type:'',//时间类型 开始时间/结束时间
            // 交易方向选择弹窗
            directionShow:false,
            //交易方向下拉
            actions:[
                {name:this.$t('common.whole'),value:2},
                {name:this.$t('common.purchase'),value:1},
                {name:this.$t('common.sellout'),value:0},
            ],
        }
    },
    computed:{
        ...mapGetters(['account','isSmall','collectList','isMobile','theme','email']),
        // 盘口信息
        open(){
            return this.inp_exchange_value+'     '+this.inp_yenUnit1.toUpperCase()+'/'+this.inp_yenUnit2.toUpperCase()
        },
        // 查看交易icon
        chainIcon(){
            if(this.chain=='bsc'){
                return 'bsc.webp'
            }else if(this.chain=='eth'){
                return 'eth.jpg'
            }else if(this.chain=='fantom'){
                return 'fantom.png'
            }else if(this.chain=='heco'){
                return 'heco.svg'
            }else if(this.chain=='matic'){
                return 'matic.webp'
            }else if(this.chain=='metis'){
                return 'metis.png'
            }else if(this.chain=='oec'){
                return 'oec.png'
            }else if(this.chain=='trx'){
                return 'trx.png'
            }
            else if(this.chain=='arbi'){
                return 'arbi.svg'
            }
        },
        // 最小时间
        isDisabled(){
            return {
                disabledDate:(time)=>{
                    var now=new Date()
                    return time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                },
            }
        },
		// 开始时间
        maxTime() {
            return {
                disabledDate:(time)=>{
                    var now=new Date()
                    if (this.inp_end_time) { // 如果结束时间不为空，则小于结束时间
                        return this.inp_end_time < time.getTime()||time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                    }else{
                        return time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                    }
                }
            }
        },
        // 结束时间
        minTime() {
            return {
                disabledDate:(time)=>{
                    var now=new Date()
                    if (this.inp_start_time) { // 如果开始时间不为空，则结束时间大于等于开始时间
                        return this.inp_start_time> time.getTime()||time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                    }
                    else {
                    	return time.getTime()<new Date(now.getTime()-3*30*24*60*60*1000)
                    }
                }
            }
        },

        // 移动端
        min_date(){
            var min_date
            var now=new Date()
            if(this.type=='start'){
                min_date=new Date(now.getTime()-3*30*24*60*60*1000)
            }else{
                min_date=this.vant_start_time?new Date(this.vant_start_time.replace(/\-/g,'/')):new Date(now.getTime()-3*30*24*60*60*1000)
            }
            return min_date
        },
        max_date(){
            var max_date
            if(this.type=='start'){
                max_date=this.vant_end_time?new Date(this.vant_end_time.replace(/\-/g,'/')):new Date()
            }else{
                max_date=new Date()
            }
            return max_date
        },
        // 交易方向名称
        direction_name(){
            try{
                this.actions.forEach(item=>{
                    if(item.value==this.vant_direction){
                        throw(item.name)
                    }
                })
            }
            catch(name){
                return name
            }
        },
    },
    created(){
        var start_time=this.getlastMonth()
        var end_time=new Date()
        this.inp_dateValue=[start_time,end_time]
        this.inp_start_time=start_time
        this.inp_end_time=end_time
        this.dateValue=[start_time,end_time]
        this.start_time=start_time
        this.end_time=end_time
        this.vant_start_time=timeFormat(start_time,'dateTime')
        this.vant_end_time=timeFormat(end_time,'dateTime')
        // 获取交易记录列表
        this.getRecord()

        // 获取收藏列表
        this.getCollectList()

        // 筛选弹窗-移动端判定
        bus.$on('filter',()=>{
            this.openFilter()
        })
    },
    methods: {
        // 导出交易明细
        exportExcel(){
            if(this.email){
                // 交易明细将发送至您的邮箱，请注意查收
                this.$confirm(this.$t('tip.exportSend'),this.$t('tip.tips'),{
                    confirmButtonText: this.$t('common.confirm'),
                    cancelButtonText: this.$t('common.cancel'),
                    center: true,
                    customClass: 'deleteOk',
                    // showClose:false,
                    // showCancelButton:false,
                }).then(() => {
                    exportRecordsSend({
                        web:this.web,
                        currency:this.yenUnit1+'_'+this.yenUnit2,
                        account:this.account
                    }).then(()=>{
                        this.$message({
                            message: this.$t('tip.sendSuc'),
                            center: true,
                            type: 'success',
                            customClass: 'shotMsg',
                        });
                    })
                })
            }else{
                // 打开绑定邮箱弹窗
                this.$refs.emailBind.open()
            }
        },
        // 获取一个月前的日期
        getlastMonth() {
            let now = new Date();
            // 当前月的日期
            let nowDate = now.getDate();
            let lastMonth = new Date(now.getTime());
            // 设置上一个月（这里不需要减1）
            lastMonth.setMonth(lastMonth.getMonth());
            // 设置为0，默认为当前月的最后一天
            lastMonth.setDate(0);
            // 上一个月的天数
            let daysOflastMonth = lastMonth.getDate();
            // 设置上一个月的日期，如果当前月的日期大于上个月的总天数，则为最后一天 
            lastMonth.setDate(nowDate > daysOflastMonth ? daysOflastMonth : nowDate);
            return lastMonth;
        },
        // 打开选择盘口弹窗
        openPopCollect(){
            this.popexchange=this.inp_exchange_value
            this.popweb=this.inp_web
            this.popyenUnit1=this.inp_yenUnit1
            this.popyenUnit2=this.inp_yenUnit2
            this.popCollection=true
        },
        // 查看交易
        jumpOut(id){
            window.open(this.chain_url+id,'_blank')
        },
        // 复制地址
        handleCopy(code) {
            let clipboard = new Clipboard('.copyBtn', {
                text: () => {
                    return code
                }
            })
            clipboard.on('success', () => {
                this.$message({
                    message: this.$t('tip.copysuccess'),
                    type: 'success',
                    center: true,
                    customClass: 'shotMsg',
                });
                clipboard.destroy();
            })
            clipboard.on('error', () => {
                this.$message({
                    message: this.$t('tip.copyerr'),
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
                clipboard.destroy();
            })
        },
        // 加载更多
        load(){
            // console.log('get-load')
            this.page++
            this.getRecord();
        },
        // 获取交易记录列表
        getRecord(){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });
			var start_time,end_time;
            if(this.isSmall=='small'){
                // 日期没问题 时间选择时 结束时间比开始时间小
                if(this.start_time>this.end_time){
                    start_time=this.end_time?timeFormat(this.end_time,'dateTime'):'';
                    end_time=this.start_time?timeFormat(this.start_time,'dateTime'):'';
                }else{
                    start_time=this.start_time?timeFormat(this.start_time,'dateTime'):'';
                    end_time=this.end_time?timeFormat(this.end_time,'dateTime'):'';
                }
            }else{
                if(this.dateValue[0]>this.dateValue[1]){
                    start_time=this.dateValue&&this.dateValue[1]?timeFormat(this.dateValue[1],'dateTime'):'';
                    end_time=this.dateValue&&this.dateValue[0]?timeFormat(this.dateValue[0],'dateTime'):'';
                }else{
                    start_time=this.dateValue&&this.dateValue[0]?timeFormat(this.dateValue[0],'dateTime'):'';
                    end_time=this.dateValue&&this.dateValue[1]?timeFormat(this.dateValue[1],'dateTime'):'';
                }
            }
            var data={
                web:this.web,
                account:this.account,
                currency:this.yenUnit1+'_'+this.yenUnit2,
                page:this.page,
                start_time:start_time,
                end_time:end_time,
                wallet_address:this.address,
                trade_type:this.direction
            }
            if(this.web_type==1){
                getDexOrders(data).then(res=>{
                    // console.log(res)
                    // this.recordList=res
                    this.chain=res.chain;
                    this.chain_url=res.chain_url;
                    if(!!res&&!!res.orders&&res.orders.length>0){
                        this.recordList.push(...res.orders);
                    }else{
                        this.load_disabled=true;
                    }
                    loading.close();
                }).catch(err=>{
                    loading.close();
                })
            }else{
                getCexOrders(data).then(res=>{
                    // console.log(res)
                    // this.recordList=res
                    if(!!res&&!!res.orders&&res.orders.length>0){
                        this.recordList.push(...res.orders);
                    }else{
                        this.load_disabled=true;
                    }
                    loading.close();
                }).catch(err=>{
                    loading.close();
                })
            }
        },
        // 钱包地址格式化
        addressFormat(address){
            return addressFormat(address)
        },
        // 获取收藏列表
        getCollectList(){
			this.$store.dispatch('markets/getCollectList');
        },
        // 取消收藏
        collectCancel(currency,web_id){
            collectCancel({
                account:this.account,
                currency,
                web_id
            }).then(res=>{
                this.$message({
                    message: this.$t('tip.cancelCollectionSuc'),
                    type: 'success',
                    center: true,
                    customClass: 'shotMsg',
                });
                this.getCollectList()
            })
        },
        // 常用填充
        commonlyTc(item){
            this.popweb=item.web;
            this.popyenUnit1=item.currency.split('_')[0];
            this.popyenUnit2=item.currency.split('_')[1];
            this.popexchange=item.exchange_name;
            this.popweb_type=item.web_type;
            this.handleInConfirm()
        },
        // 确认进入盘口
        handleInConfirm(){
            // 非空验证
            if(!this.popweb){
                this.$message({
                    message:this.$t('tip.selectExchange')+'！',
                    type: 'error',
                    center: true,
                    customClass: 'shotMsg',
                });
            }
            else if(this.popweb!='opensea'){
                if(!this.popyenUnit1||!this.popyenUnit2){
                    this.$message({
                        message:this.$t('tip.enterPair')+'！',
                        type: 'error',
                        center: true,
                        customClass: 'shotMsg',
                    });
                }else{
                    this.inp_exchange_value=this.popexchange
                    this.inp_web=this.popweb
                    this.inp_yenUnit1=this.popyenUnit1
                    this.inp_yenUnit2=this.popyenUnit2
                    this.inp_web_type=this.popweb_type
                    this.popCollection=false;
                }
            }
        },
        // 时间日期选择区间
        selectTime(values){
            if(values){
                this.inp_start_time=values[0]
                this.inp_end_time=values[1]
            }
        },
        // 设定筛选条件，并获取筛选列表
        handleSearch() {
            // 初始化
            this.page=1;
            this.load_disabled=false;
            this.recordList=[];

            // PC-无限滚动-固化变量
            this.dateValue =this.inp_dateValue;
            this.start_time =this.inp_start_time;
            this.end_time =this.inp_end_time;
            this.web =this.inp_web;
            this.web_type =this.inp_web_type;
            this.yenUnit1 =this.inp_yenUnit1;
            this.yenUnit2 =this.inp_yenUnit2;
            this.direction =this.inp_direction;
            this.address =this.inp_address;
            
            // 搜索
            this.getRecord();
        },
        // 交易所
        closeExchange() {
            this.popExchange = false;
        },
        handleSelect(data) {
            // console.log(data)
            this.popexchange = data.text;
            this.popweb = data.web;
            this.popweb_type = data.web_type;
            
            // 移动端
            this.vant_exchange_value = data.text;
            this.vant_web = data.web;
            this.vant_web_type = data.web_type;

            this.popExchange = false;
        },

        // 打开筛选弹窗 - 移动端
        openFilter(){
            this.vant_exchange_value=this.exchangeValue
            this.vant_web=this.web
            this.vant_yenUnit1=this.yenUnit1
            this.vant_yenUnit2=this.yenUnit2
            this.vant_start_time=timeFormat(this.start_time,'dateTime')
            this.vant_end_time=timeFormat(this.end_time,'dateTime')
            
            this.filter_show=true
        },
        // 关闭筛选弹窗
        closeAction() {
            this.filter_show = false;
            // this.$nextTick(()=>{
            // 	this.form = {};
            // 	this.activeItem = null;
            // 	this.web=null;
            // })
        },
        // 打开时间选择器
        openDateTime(type){
            this.type=type
            if(type=='start'){
                this.currentDate=this.vant_start_time||new Date()
            }else{
                this.currentDate=this.vant_end_time||new Date()
            }
            this.dateTimeBox=true;
        },
        // 时间选择器视图
        formatter(type, val) {
            if (type === 'year') {
                return val + '年';
            }
            if (type === 'month') {
                return val + '月';
            }
            if (type === 'day') {
                return val + '日';
            }
            if (type === 'hour') {
                return val + '时';
            }
            if (type === 'minute') {
                return val + '分';
            }
            return val;
        },
        setTime(e){
            if(this.type=='start'){
                this.vant_start_time=timeFormat(e,'dateTime')
            }else{
                this.vant_end_time=timeFormat(e,'dateTime')
            }
            this.dateTimeBox=false
        },
        // 移动端-筛选确定
        handleSearch2(type) {
            if(type!='keywords'){
                this.start_time=this.vant_start_time
                this.end_time=this.vant_end_time
                this.exchangeValue=this.vant_exchange_value
                this.direction=this.vant_direction
                this.web=this.vant_web
                this.web_type=this.vant_web_type
                this.yenUnit1=this.vant_yenUnit1
                this.yenUnit2=this.vant_yenUnit2
            }
            // 初始化
            this.page=1;
            this.load_disabled=false;
            this.recordList=[];
            this.isFirst='0';
            this.filter_show=false
            // 搜索
            this.getRecord2();
        },
        // 移动端-获取记录
        getRecord2(){
            const loading = Loading.service({
                target: '.main',
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
                customClass: 'apploading',
            });

            var start_time,end_time;
            if(this.start_time&&this.end_time){
                var dateValue1=new Date(this.start_time?.replace(/\-/g,'/')).getTime()
                var dateValue2=new Date(this.end_time?.replace(/\-/g,'/')).getTime()
                if(dateValue1>dateValue2){
                    start_time=this.end_time;
                    end_time=this.start_time;
                }else{
                    start_time=this.start_time
                    end_time=this.end_time
                }
            }else{
                start_time=this.start_time
                end_time=this.end_time
            }
            // console.log(this.web)
            if(this.web_type==1){
                getDexOrders({
                    web:this.web,
                    account:this.account,
                    currency:this.yenUnit1+'_'+this.yenUnit2,
                    page:this.page,
                    start_time:start_time,
                    end_time:end_time,
                    wallet_address:this.address,
                    trade_type:this.direction
                }).then(res=>{
                    // console.log(res)
                    this.chain=res.chain;
                    this.chain_url=res.chain_url;
                    if(!!res&&!!res.orders&&res.orders.length>0){
                        this.recordList.push(...res.orders);
                        // this.recordList=res
                    }else{
                        this.load_disabled=true;
                    }
                    loading.close();
                }).catch(err=>{
                    loading.close();
                })
            }else{
                getCexOrders({
                    web:this.web,//'coinw',
                    account:this.account,//'mrx6688',
                    currency:this.yenUnit1+'_'+this.yenUnit2,//'mrx_usdt',
                    page:this.page,
                    start_time:start_time,
                    end_time:end_time,
                    wallet_address:this.address,
                    trade_type:this.direction
                }).then(res=>{
                    // console.log(res)
                    // this.recordList=res
                    if(!!res&&!!res.orders&&res.orders.length>0){
                        this.recordList.push(...res.orders);
                    }else{
                        this.load_disabled=true;
                    }
                    loading.close();
                }).catch(err=>{
                    loading.close();
                })
            }
        },
        // 移动端-交易方向选择
        directionSelect(item){
            this.directionShow = false;
            this.vant_direction=item.value
        },
    },
    beforeDestroy() {
        // 子组件销毁
        if(this.$refs.emailBind){
            this.$refs.emailBind.beforeDestroy()
        }
    }
})
</script>