<template>
	<!-- 绑定邮箱 -->
	<el-dialog :title="$t('user.bindEmail')" :visible.sync="showEmail" :before-close="emailCancel">
		<div class="add_form">
			<div class="bind_form">
				<div class="item">
					<div class="title">{{$t('common.newEmail')}}</div>
					<div class="text">
						<input
							class="inp"
							:placeholder="email_form.email?'':$t('common.input')"
							v-model="email_form.email"
						/>
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.verification')}}</div>
					<div class="text">
						<div class="inp_code">
							<input
								class="inp"
								:placeholder="email_form.sms_code?'':$t('common.inputVerification')"
								type="text"
								v-model="email_form.sms_code"
							/>
							<div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{ timeCount }}s</div>
							<div v-else class="g_btn btn" @click="handleSendCode('email')">
								<template v-if="firstSend">{{$t('common.sendVerification')}}</template>
								<template v-else>{{$t('common.resend')}}</template>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="g_dialogBtns">
				<div class="g_btn btn" @click="emailCancel">{{$t('common.cancel')}}</div>
				<div class="g_btn btn submit" @click="editEmail">{{$t('common.confirm2')}}</div>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import { Loading } from 'element-ui';
    import {sendEmail,updateEmail } from '@/api/user'

	export default ({
        data() {
            return {
                loading:null,
                timer:null,
				// 绑定邮箱
				showEmail: false,
            	email_form:{},

				// 邮箱验证
				codeSending: false,
				timeCount: 60,
				firstSend: true,
            };
        },
        methods: {
            // 父组件页面销毁事件
            beforeDestroy(){
                if(this.timer){
                    clearInterval(this.timer);
                    this.timer=null
                }
            },
            open(){
                // 请您绑定邮箱
                this.$confirm(this.$t('tip.noEmail'),this.$t('tip.tips'),{
                    confirmButtonText: this.$t('common.toBind'),
                    cancelButtonText: this.$t('common.cancel'),
                    center: true,
                    customClass: 'deleteOk',
                    showClose:false,
                }).then(() => {
                    this.showEmail=true
                })
            },
			// 绑定邮箱提交
			editEmail(){
				var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
				// /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				if(!this.email_form.email){
					this.$message({
						message: this.$t('tip.emailEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!emailReg.test(this.email_form.email) ){
					this.$message({
						message: this.$t('tip.emailVer')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else if(!this.email_form.sms_code){
					this.$message({
						message: this.$t('tip.codeEmpty')+'！',
						type: 'error',
						center: true,
						offset: 100,
						customClass: 'shotMsg',
					});
				}else{
					updateEmail({
						account:this.account,
						email:this.email_form.email,
						sms_code:this.email_form.sms_code,
						type:0,
					}).then(res=>{
						if(res==1){
							this.getInfo()
							// 修改成功
							this.$message({
								message: this.$t('tip.editSuc'),
								type: 'success',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
							this.showEmail = false;
							this.email_form = {};
							
							clearInterval(this.timer);
							this.codeSending = false;
							this.firstSend = true;
						}else{
							// 修改失败
							this.$message({
								message: this.$t('tip.editErr'),
								type: 'error',
								center: true,
								offset: 100,
								customClass: 'shotMsg',
							});
						}
					})
				}
			},
            
			// 发送验证码
			handleSendCode(type) {
				if(!this.codeSending){
                    var emailReg=/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
                    // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    if(!this.email_form.email){
                        this.$message({
                            message: this.$t('tip.emailEmpty')+'！',
                            type: 'error',
                            center: true,
                            offset: 100,
                            customClass: 'shotMsg',
                        });
                        return
                    }else if(!emailReg.test(this.email_form.email) ){
                        this.$message({
                            message: this.$t('tip.emailVer')+'！',
                            type: 'error',
                            center: true,
                            offset: 100,
                            customClass: 'shotMsg',
                        });
                        return
                    }else{
                        // 发送验证码
                        this.timeCount = 60;
                        this.codeSending = true;
                        let that = this;
                        this.firstSend = false;
                        clearInterval(this.timer);
                        this.timer = setInterval(() => {
                            if(this.timeCount >= 1) {
                                that.timeCount -= 1;
                            } else {
                                clearInterval(this.timer);
                                this.codeSending = false;
                            }
                        }, 1000);

                        sendEmail({
                            account:this.account,
                            email:this.email_form.email,
                            type:2,
                        })
                    }
				}
            },

            // 关闭绑定邮箱弹窗
			emailCancel() {
				this.showEmail = false;
				this.email_form = {};
				
				clearInterval(this.timer);
				this.codeSending = false;
				this.firstSend = true;
			},
            // 获取用户信息
			getInfo(){
				this.loading = Loading.service({
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				this.$store.dispatch('user/getInfo').then(res=>{
					this.loading.close();
				}).catch(err=>{
					this.loading.close();
				})
			},
        },
        beforeDestroy(){
            this.beforeDestroy()
            // if(this.timer){
            //     clearInterval(this.timer);
            //     this.timer=null
            // }
        }
    })
</script>

<style scoped="scoped" lang="stylus">
    @import '~@/assets/style/var.styl';
    
    // 绑定邮箱
    .add_form {
        color var(--white)
        font-size 14px
        flexbox()
        flex-direction column
        align-items center
        .bind_form {
            .item {
                margin-bottom 24px
                flexbox()
                align-items center
                &.disable{
                    opacity 0.4
                    .inp{
                        cursor not-allowed
                    }
                }
                .title {
                    width 6em
                    line-height 32px
                    margin-right 8px
                }
                .text {
                    font-size 12px
                    flex: 1;
                    .inp {
                        radius(2px)
                        color var(--white)
                        padding 0 12px
                        width 100%
                        height 32px
                        border 1px solid var(--border2)
                        &[type=number]{
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                            }
                        }
                    }
                    .tips {
                        color var(--active)
                    }
                    .inp_code {
                        flexbox()
                        width 100%
                        .inp {
                            flex 1
                        }
                        .btn {
                            color #ffffff
                            min-width 86px
                            height 32px
                            background-color var(--active)
                            margin-left 8px
                            &.disabled {
                                background-color var(--disable)
                                cursor not-allowed
                            }
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 750px) {
        .add_form .bind_form{
            .item{
                .title{
                    width 5em
                }
                .text{
                    width 45vw
                    .inp{
                        width 100%
                    }
                    .inp_code{
                        width 45vw
                        .btn{
                            min-width 75px
                        }
                    }
                }
            }
        }
    }
</style>